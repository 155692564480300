@import '../../index.scss';

.company-info-accordion-container {
  border: 1px solid $primaryColor;
  margin-top: $big-space;
  width: 100%;
  .accordion-summary {
    color: $white;
    background-color: $primaryColor;

    .MuiSvgIcon-root {
      color: $white;
      font-size: $icon-size-small;
    }
  }
  .accordion-details{
      margin-bottom: 1px !important;
      .info-content {
        padding: 24px;

        .preview-edit-btn {
          background-color: $primaryColorGraduate;
          width: 100%;
          height: 30px;

          &:hover {
            background-color: $primaryColor;
          }
        }

        .divider-container {
          display: flex;
          justify-content: center;
          align-items: center;

          .divider {
            width: 100%;
            height: 0px;
            margin: 0 $short-space;
          }
        }

      }
    
  }
}
