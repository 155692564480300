@import '../../../index.scss';

.welcome-section{
    width: 100%;
    background-color: $softGray !important;
    margin-bottom: 0;
    .welcome-margin{
        max-width: $max-breakpoint-limit;
        margin: 0 auto;
        padding-top: 30px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        @media only screen and (max-width: $breakpoint-1125){
            max-width: 90%;
        }
    }
}
.state-offers-section{
    background-color: $secondaryColor;
    margin-top: 0 !important;
}

.profiles-container{
    margin: $medium-space 0 !important;
    .line{
        border-bottom: 1px solid $softGray;
        margin-bottom: 20px;
    }
    .profile-text{
        @extend .regular-serif;
        color: $primaryColor;
    }
    .btn-container{
        margin: 15px 0;
        .add-profile-btn{
            background-color: $primaryColorGraduate;
            color: $white;
            @media only screen and (max-width: $breakpoint-540) {
                font-size: $font-size-h7;
            }
            &:disabled {
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }
    }
}

.title-section-line{
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    .group-icon{
        margin: 10px;
        color: $primaryColor;
    }
}

//modifiy form
.modify-offer-view{
    background-color: $white;

    .group-icon {
        margin: 10px;
        color: $primaryColor;
    }

    .line {
        border-bottom: 1px solid $softGray;
        margin-bottom: 20px;
        padding-right: 50px;
    }
}

.edit-offer-btn{
    background-color: $secondaryColor !important;
    color: $white !important;
    width: 120px;
    margin-left: 60px;
    &:hover {
        background-color: $primaryColor !important;
    }
    &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
    }
}

.add-offer-btn {
    background-color: $warningColor !important;
    color: $black !important;
    width: 253px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
    }
}