@import '../../../index.scss';

.success-container{
    background-color: $lightGray;
    background-image: url('../../../assets/images/SIE_Fondo_Textura_2b.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    height: 80vh;
    @media only screen and (max-width: $breakpoint-540){
        height: auto;
    }
    .success-notification{
        width: 80%;
        display: flex;
        justify-content: center;
        .alert-box{
            height: auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            background-color: $white;
            padding: $big-space;
            box-shadow: 2px 2px 6px $mediumGray !important;
            .alert-icon{
                margin: $small-space;
                transform: scale(1.5);
            }
            .alert-title{
                @extend .bold-serif;
                margin: $small-space 0;
            }
            .alert-desc{
                @extend .regular-serif;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                margin: 0;
                padding: 0;
            }
            .alert-btn{
                background-color: $primaryColorGraduate;
                color: $white;
            }
        }
    }
}