@import '../../index.scss';

.recover-msg-container {
    padding: $small-space-secction $lmedium-space-secction;
    border: 1px solid $softGray;
    border-radius: 5px;
    opacity: 1;
    margin: 0 auto;
    @media only screen and (max-width: $breakpoint-540) {
        padding: $short-space-secction;
    }
    .security-icon {
        font-size: $icon-size-big;

        @media only screen and (max-width: $breakpoint-720) {
            font-size: $icon-size-medium;
        }
    }

    .recover-title {
        @extend .light-serif;
        color: $primaryColor;
        padding: $small-space;
        line-height: 1.1;

        @media only screen and (max-width: $breakpoint-720) {
            font-size: 28px;
        }
    }

    .buttons-Colors {
        margin: 0px 40px;
        margin-bottom: $small-space-secction;
    }

    .box-recover-container {
        border: 1px solid $primaryColor;
        border-radius: 5px;
        justify-content: center;
        margin-bottom: 15px;
        margin-left: 40px;
        margin-right: 40px;

        @media only screen and (max-width: $breakpoint-720) {
            margin: 15px 0px;
        }

        .box-text {
            @extend .bold-serif;
            opacity: 1;
            margin: $medium-space;
            line-height: 1.1;
        }

        .description-recover-container {
            color: $black;
            display: flex;
            justify-content: center;
            .warning-recovery-icon {
                font-size: $icon-size-small;
                margin-right: $small-space;
            }
        }
        .email-response-text {
            color: $black;
            margin: $short-space-secction;
            justify-content: center;
        }
    }

    .submit-button {
        padding: $medium-space;
        justify-content: flex-end !important;

        .sub-btn {
            background-color: $secondaryColor;
            color: $white;
            margin: $small-space;
        }
    }

}