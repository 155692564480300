@import '../../../index.scss';

.end-offer-info-container {
    margin-left: 35px;
    @media only screen and (max-width: $breakpoint-540) {
        margin: $short-space 0px;
    }

   .description-end-offer-container {
        padding: $big-space $lmedium-space-secction;
        @media only screen and (max-width: $breakpoint-540) {
            padding: $big-space;
        }
    }
}