@import '../../../index.scss';

.offer-form-info-container{
  .summary-offer-accordion-container {
    margin-bottom: $big-space !important;
    border: 1px solid $primaryColor;
    .accordion-summary {
      color: $white;
      background-color: $primaryColor;
      .MuiSvgIcon-root {
        color: $white;
        font-size: $icon-size-small;
      }
    }
  }

  .summary-offer-accordion-details {
    overflow-y: auto;
    overflow-x: hidden;
    padding: $small-space $small-space-secction !important;

    .field-box-margin {
      margin-top: $medium-space !important;
      margin-bottom: $medium-space !important;
      color: $primaryColorCompany;
      .field-box-title-special{
        @extend .bold-serif;
        width: 200px;
      }
      .field-title-typePosition{
        width: 100%;
        @media only screen and (max-width: $breakpoint-1125) {
          width: 190px;
        }
        @media only screen and (max-width: $breakpoint-540) {
          width: 100%;
        }
      }
      .numberVacancy-box{
        width: 40%;
        @media only screen and (max-width: $breakpoint-960) {
          width: 70%;
        }
      }
      .red-title {
        @extend .bold-serif;
        color: $primaryError;
      }
      .warning-alert-text{
        font-size: $font-size-h6;
          @extend .regular-serif;
          color: $primaryColor;
      }
    }

    .add-container {
      text-align: end;
      @media only screen and (max-width: $breakpoint-540) {
        text-align: center;
      }
      .add-btn {
        margin-top: 40px;
        height: 40px;
        background-color: $secondaryColor;
        color: $white;
        @media only screen and (max-width: $breakpoint-540) {
          margin-top: 0;
          margin-bottom: 20px;
        }
        &:disabled {
          background-color: $softGray !important;
          color: $mediumGray !important;
        }
      }
    }

    .salary-container{
      margin-top: $medium-space !important;
      margin-bottom: $medium-space !important;
      border: 1px solid $mediumGray !important;
      @media only screen and (max-width: $breakpoint-540) {
        padding: $medium-space;
      }
      .special-variable-salary-container{
        margin-top: -$lmedium-space-secction !important;
        @media only screen and (max-width: $breakpoint-540) {
          margin-top: -$short-space !important;
        }
      }
    }

    .divider-accordion {
      width: 100% !important;
      height: 0px !important;
      border: 1px solid $softGray !important;
    }

    .detail-container{
      justify-content: space-between !important;
      border: 1px solid $softGray;
      background-color: $grayRecaptcha;
      margin: $short-space 0;
      .detail-description{
        color: $darkMediumGray;
        margin: 5px;
      }
      .del-btn{
        color: $primaryError;
        background-color: $grayRecaptcha;
        border: 1px solid $primaryError;
        margin: 5px;
      }
    }

    .inside-accordion-container{
      justify-content: center;
      .summary-inside-accordion-container {
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid $mediumGray;

        .accordion-summary {
          color: $primaryColorCompany;
          background-color: $white;

          .MuiSvgIcon-root {
            color: $primaryColorCompany;
            font-size: $icon-size-small;
          }
        }
      }
      .summary-inside-accordion-details {
        margin: 0;
        padding: 0;
      }
    }
    .checkbox-container{
      margin-top: 20px;
      .checkbox {
        margin: 0 !important;
        padding: 0;

        .MuiFormControlLabel-label {
          font-size: $font-size-h5;
          @extend .regular-serif;
          color: $primaryColor;
        }

        .Mui-checked {
          color: $secondaryColor;
        }
      }
    }
  }
}

.space-notification-boxes{
  width: 100%;
  margin-top: $short-space-secction !important;
}


.checkbox-top-curriculum-container {
    @extend .bold-serif;
    display: flex;
    align-content: center;
    align-items: center;
    .checkbox-top {
        margin: 0 $small-space !important;
        .MuiFormControlLabel-label {
            color: $primaryColorCompany;
            font-family: $Ancizar-bold-serif;
            font-size: $font-size-h5;
        }
        .Mui-checked {
            color: $secondaryColor;
        }
    }
}
.buttons-container-offer{
  justify-content: space-between;
  margin-top: $short-space-secction !important;
  .confirm-button{
    background-color: $secondaryColor;
    color: $white;
    &:disabled {
      background-color: $softGray !important;
      color: $mediumGray !important;
    }
    &:hover{
      background-color: $primaryColor;
      color: $white;
    }
  }
  .cancel-button{
    background-color: $white;
    color: $primaryColorGraduate;
    border: 1px solid $primaryColorGraduate;

  }
}