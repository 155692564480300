@import '../../../index.scss';

.welcome-accordion-container {
  .summary-welcome-accordion-container {
    margin: $small-space 0;
    border: 1px solid $mediumGray;

    .accordion-summary {
      color: $primaryColorCompany;
      background-color: $white;

      .MuiSvgIcon-root {
        color: $primaryColorCompany;
        font-size: $icon-size-small;
      }

      .welcome-title{
        color: $primaryColor;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        @media only screen and (max-width: $breakpoint-540) {
          font-size: $font-size-h4;
          justify-content: start;
        }
        .welcome-name {
          font-family: $Ancizar-bold-serif;
          padding-left: $short-space;
          @media only screen and (max-width: $breakpoint-540) {
            font-size: $font-size-h4;
          }
        }
      }

    }
  }
  .welcome-accordion-details {
    padding: $big-space;
    .welcome-text {
      @extend .regular-serif;
      color: $primaryColor;
      margin-bottom: 0;
    }
  }
}