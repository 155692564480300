@import '../../index.scss';

.landing-container{
    background-color: $white;
}

.login-box{
    justify-content: flex-start;
    @media only screen and (max-width: $breakpoint-960){
        justify-content: center;
    }
}
.register-box{
    justify-content: center;
    @media only screen and (max-width: $breakpoint-720){
        margin-top: $big-space !important;
        height: auto;
    }
}
