@import '../../index.scss';

.title-container{
  margin: $medium-space 0 !important;
  .title-section-line {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;

    .group-icon {
      margin: 10px;
      color: $primaryColor;
    }

    .event-title {
      @extend .bold-serif;
      font-size: $font-size-h2;
      color: $primaryColor;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .line {
    border-bottom: 1px solid $softGray;
    margin-bottom: 20px;
  }
}
.alert-container{
  margin: 10px 0;
  width: 100%;
  .alert-title {
    font-size: $font-size-h4;
    @extend .bold-serif;
    color: $primaryColor;
  }
  .alert-description {
    font-size: $font-size-h5;
    @extend .regular-serif;
    color: $primaryColor;
  }
}

.parameter-container{
  margin-bottom: $medium-space !important;
  border: 1px solid $primaryColor;
  border-radius: $short-space;

  .title-container {
    margin: 0 !important;
    background-color: $primaryColor;
    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .parameter-content{
    padding: $medium-space $small-space-secction;

    .field-box-margin {
      margin-top: $medium-space !important;
      margin-bottom: $medium-space !important;

      .field-box-title {
        color: $primaryColorCompany;
        @extend .bold-serif;
        margin-bottom: 10px;
      }
      .text-info {
        color: $primaryColor;
        @extend .regular-serif;
        margin-bottom: 10px;
      }
    }
  }
}

.buttons-container-parameter {
  margin-top: $short-space-secction !important;

  .right {
    justify-content: flex-end;
    .confirm-button {
      background-color: $secondaryColor;
      color: $white;
      &:disabled {
        background-color: $softGray !important;
      }
    }
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: center !important;
    }

  }

  .left {
    justify-content: flex-start;
    .cancel-button {
      background-color: $white;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;
    }
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: center !important;
    }
  }
}