@import '../../../index.scss';

#shortDescription-cell{
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    @extend .regular-serif;
    white-space: pre-line !important;
    line-height: 1.2 !important;
    padding: 10px 0 !important;
}

#name-cell2 {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    white-space: pre-line !important;
    line-height: 1.2 !important;
    padding: 10px 0 !important;
    color: $black !important;
    @extend .bold-serif;
}