@font-face {
  font-family: 'Ancizar Sans bold';
  src: url('./assets//Fonts/AncizarSans_02042016/AncizarSans-Bold_02042016.otf') format('opentype');
}
@font-face {
  font-family: 'Ancizar Sans Regular';
  src: url('./assets//Fonts/AncizarSans_02042016/AncizarSans-Regular_02042016.otf') format('opentype');
}
@font-face {
  font-family: 'Ancizar Sans Light';
  src: url('./assets//Fonts/AncizarSans_02042016/AncizarSans-Light_02042016.otf') format('opentype');
}
@font-face {
  font-family: 'Ancizar Sans Thin';
  src: url('./assets//Fonts/AncizarSans_02042016/AncizarSans-Thin_02042016.otf') format('opentype');
}
@font-face {
  font-family: 'Ancizar Sans Bold Italic';
  src: url('./assets//Fonts/AncizarSans_02042016/AncizarSans-BoldItalic_02042016.otf') format('opentype');
}
// Font Variables
$Ancizar-bold-serif: 'Ancizar Sans Bold';
$Ancizar-regular-serif: 'Ancizar Sans Regular';
$Ancizar-light-serif: 'Ancizar Sans Light';
$Ancizar-thin-serif: 'Ancizar Sans Thin';
$Ancizar-bold-italic-serif: 'Ancizar Sans Bold Italic';

// base colors
$primaryColor:#004B73;
$secondaryColor: #14A5DC;
$primaryColorGraduate: #7DA52D;
$primaryColorGraduateLight: #AFD75E;
$primaryColorCompany: #00B5C0;
$primaryColorCompanyLight: #5EE8F3;
$softBlue: #7fd9df;
$disabledTextGray: #00000042;
$darkMediumGray: #373737;
$darkGray: #666666;
$mediumGray: #969696;
$softGray:#D7D7D7;
$lightGray:#F2F2F2;
$grayRecaptcha: #f9f9f9;
$warningColor: #FBBA2B;
$white: #ffffff;
$black:#000000;
$darkGreen: #6f872c;
$greenSuccess: #99C246;
$lightGreen: #d6eaae;
$primaryError: #f44336;
$blueNotification: #56A4E2;
$orangeDark: #CF6800;
$orangeColor: #FBAA2B;
$browColor: #664D03;
$darkBrown: #504F4F;
$stepperBackGround: #DEF3F9;
// font-size variables
$font-size-h1: 32px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$font-size-h7: 12px;

// icon-size variables
$icon-size-big: 60px;
$icon-size-medium: 40px;
$icon-size-small: 20px;

// breakpoints
$breakpoint-320: 320px;
$breakpoint-540: 540px;
$breakpoint-720: 720px;
$breakpoint-800: 800px;
$breakpoint-960: 960px;
$breakpoint-1125: 1125px;
$breakpoint-1280: 1280px;
$max-breakpoint-limit: 1150px;
$medium-breakpoint-limit: 90%;
$small-breakpoint-limit: 90%;

// spaces-padding-margin
$short-space: 5px;
$small-space: 8px;
$medium-space: 12px;
$big-space: 16px;
$short-space-secction: 18px;
$small-space-secction: 28px;
$lmedium-space-secction: 42px;
$large-space-secction: 65px;

// general styles
html {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  line-height: 1.3;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header{
  z-index: 10;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
.MuiToolbar-regular {
  min-height: 64px !important;
}
.MuiButton-containedSizeSmall{
  padding: 6px 10px 4px !important;
}
.MuiButton-root{
  text-transform: none !important;
}
.MuiInputBase-root{
  font-size: $font-size-h5 !important;
}
.MuiOutlinedInput-input{
  // padding: 16.5px 14px;
  min-height: 28px;
  padding: 6px 10px!important;
  vertical-align: center;
}
.MuiChip-label{
  white-space: break-spaces !important;
}
.MuiPaper-elevation8{
  box-shadow: 0px 0px 6px #969696e6 !important;
}
/*se coloca del Stepper*/
.MuiTextField-root{
  label{
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: text-top;
      font-size: 1em!important;
      margin-top: -7px;
      padding-bottom: 10px!important;
  }
}
.MuiAccordion-root{
  margin-top: 14px;
}
.MuiOutlinedInput-root{
  transition: all 500ms ease-out;
  &:hover input{
    box-shadow: 0px 0px 6px #969696e6 !important;
  }
}
/*fin de la adición del esteper*/
.MuiSelect-outlined.MuiSelect-outlined{
  padding: 10px 10px 2px!important;
  min-height: 28px;
}
.MuiFormLabel-root{
  font-size: $font-size-h5 !important;
}
.MuiTypography-root{
  line-height: 1.3 !important;
}
.MuiButton-label{
  line-height: 1.1 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
  color: $primaryColorCompany !important;
}
.MuiOutlinedInput-inputMultiline{
  padding: 0 !important;
}

.link-responsive{
  word-break: break-all;
}

// general container responsive
.general-container{
  background-color: $white;
  margin: 0;
  padding: 0;
}

// main container responsive
.main-container{
  max-width: $max-breakpoint-limit !important;
  margin: $small-space-secction auto !important;
  display: flex !important;
  flex-flow: column nowrap !important;
  justify-content: space-evenly !important;
  @media only screen and (max-width: $breakpoint-1125){
      max-width: 90% !important;
  }
}

// typography styles
.MuiTypography-h1{
  line-height: 1.3 !important;
}
.MuiTypography-h2{
  @media only screen and (max-width: $breakpoint-540) {
    font-size: $font-size-h3 !important;
  }
}
.MuiTypography-h3{
  @media only screen and (max-width: $breakpoint-540) {
    font-size: $font-size-h4 !important;
  }
}
.MuiTypography-h4{
  @media only screen and (max-width: $breakpoint-540) {
    font-size: $font-size-h5 !important;
  }
}
.bold-serif{
  font-family: $Ancizar-bold-serif !important;
  letter-spacing: 0.52px !important;
}
.regular-serif{
  font-family: $Ancizar-regular-serif !important;
  letter-spacing: 0.52px !important;
}
.light-serif{
  font-family: $Ancizar-light-serif !important;
  letter-spacing: 0.52px !important;
}
.h7-regular-primaryError{
  color: $primaryError;
  font-family: $Ancizar-regular-serif !important;
  font-size: $font-size-h7 !important;
  letter-spacing: 0.52px !important;
}

// colors classes
.primaryColor{
  color: $primaryColor !important;
}
.primaryError{
  color: $primaryError;
}
.regular-serif-primaryColor{
  @extend .regular-serif;
  color: $primaryColor;
}

.bold-serif-primaryColor{
  @extend .bold-serif;
  color: $primaryColor;
}
.bold-serif-primaryColorCompany{
  @extend .bold-serif;
  color: $primaryColorCompany;
}

//special margin style for texfields
.special-box-right{
  padding-right: $small-space;
  @media only screen and (max-width: $breakpoint-540){
      padding-right: 0;
  }
}
.special-box-left{
  padding-left: $small-space;
  @media only screen and (max-width: $breakpoint-540){
      padding-left: 0;
  }
}
.special-box-both{
  padding: 0 $small-space;
  @media only screen and (max-width: $breakpoint-540){
      padding-left: 0;
  }
}