@import '../../../index.scss';


.select-experience-content {

  .select-experience-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .group-checkbox {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .label-checkbox {
        width: 250px;

        .MuiFormControlLabel-label {
          font-size: $font-size-h5;
          @extend .regular-serif;
          color: $primaryColor;
        }

        .Mui-checked {
          color: $primaryColorCompany;
        }
      }
    }
  }
}
