@import '../../index.scss';

.stepper-container{
    margin: 0 10px;
    .MuiStepper-root{
        @media only screen and (max-width: $breakpoint-540){
            padding: $medium-space;
        }
    }
    //se adiciona para los formularios
    .MuiSelect-selectMenu{
        min-height: 28px!important;
    }
    //fin de la edición
    .step-label-text{
        font-size: $font-size-h4;
        @extend .regular-serif;
        @media only screen and (max-width: $breakpoint-540){
            font-size: $font-size-h6;
        }
        @media only screen and (max-width: $breakpoint-320){
            font-size: $font-size-h7;
        }
        font-weight: 400;
    }
    .step-header-container-graduate{
        .MuiStepLabel-label.MuiStepLabel-active {
            color: $primaryColorGraduate  !important;
            span{
                font-family: 'Ancizar Sans bold'!important;
            }
        }
        .MuiStepLabel-label.MuiStepLabel-completed {
            color: $primaryColorGraduate  !important;
        }
    }
    .step-header-container-company{
        .MuiStepLabel-label.MuiStepLabel-active {
            color: $primaryColorCompany  !important;
        }
        .MuiStepLabel-label.MuiStepLabel-completed {
            color: $primaryColorCompany !important;
        }
    }
}

.steps-header{
    height: 100px;
    width: 100% !important;
    background-color: $white !important;
    border-radius: 30px 0 30px 0;
    box-shadow: 0px 0px 6px $mediumGray !important;
    @media only screen and (max-width: $breakpoint-960){
        height: 120px;
    }
}
.steps-container{
    width: 100% !important;
    margin-top: $short-space-secction !important;
}

.special-column-right{
    padding-right: 8px;
    @media only screen and (max-width: $breakpoint-960){
        padding: 0;
    }
}

.special-column-left{
    padding-left: 8px;
    @media only screen and (max-width: $breakpoint-960){
        padding: 0;
    }
}

.field-box-margin{
    margin-top: $small-space !important;
    .field-box-title-graduate{
        @extend .bold-serif;
        color:$primaryColorGraduate
    }
    .field-box-title-company{
        @extend .bold-serif;
        color: $primaryColorCompany;
    }
    .digit_verification-box{
        width: 30%;
        @media only screen and (max-width: $breakpoint-960){
            width: 50%;
        }
    }
}

/*paso esto al index general por que aplica a todos
.MuiTextField-root{
    label{
        width: 90%;
    }
}*/

.divider-accordion{
    width: 100%;
    height: 0px;
    margin: 0;
    border: 1px solid $softGray;
}
.accordion-summary{
    margin-bottom: -1 !important;
    box-shadow: 0px 0px 6px $mediumGray !important;
    .MuiAccordionSummary-content{
        align-items: center;
    }
    .summary-content{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .summary-text{
        display: flex;
        flex-flow: column nowrap;
        padding: $small-space;
        .summary-description{
            @extend .light-serif;
            color: $black;
        }
    }
    .accordion-icon{
        background-color: $white;
        color: $greenSuccess;
        border-radius: 5px;
        height: 30px;
        &:hover{
            background-color: $lightGray;
            color: $black;
        }
        &:active{
            background-color: transparent !important;
        }
    }
}

.accordion-done-graduate{
    background-color: $greenSuccess !important;
    .summary-title{
        color: $white;
    }
    .summary-description{
        display: none;
    }
}
.accordion-edit-graduate{
    background-color: $white !important;
    .summary-title{
        color: $primaryColorGraduate;
    }
}

.accordion-done-company{
    background-color: $primaryColorCompany !important;
    .summary-title{
        color: $white;
    }
    .summary-description{
        display: none;
    }
}
.accordion-edit-company{
    background-color: $white !important;
    .summary-title{
        color: $primaryColorCompany;
    }
}

.accordion-details{
    background-color: $white !important;
    box-shadow: 0px 0px 6px $mediumGray !important;
    // margin-bottom: 10px !important;
    display: flex;
    flex-flow: column;
    padding: 8px 30px 16px !important;
    .accordion-btn{
        background-color: $secondaryColor;
        color: $white;
        &:hover{
            background-color: $primaryColor !important;
        }
    }
}
.step-container-btn{
    .step-btn{
        background-color: $secondaryColor;
        color: $white !important;
        width: 120px;
        margin: 10px 0;
        &:hover{
            background-color: $primaryColor !important;
        }
    }
    .step-back{
        background-color: $white;
        color: $primaryColorGraduate;
        width: 120px;
        margin: 10px 0;
        &:hover{
            background-color: $primaryColorGraduateLight !important;
        }
    }
}

//second step
.second-step-container{
    background-color: $white !important;
    box-shadow: 0px 0px 6px $mediumGray !important;
    .second-step-summary{
        padding: 24px;
        box-shadow: 0px 0px 6px $mediumGray !important;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        .summary-description-documents{
            @extend .regular-serif;
            color: $black;
            padding-bottom: $short-space;
        }
    }
    .second-step-content{
        box-shadow: 0px 0px 6px $mediumGray !important;
        padding: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
        .summary-title-documents{
            @extend .bold-serif;
            color: $primaryColorCompany;
        }
        .summary-description-documents{
            @extend .regular-serif;
            color: $black;
            padding-bottom: $short-space;
        }
        .card-container{
            height: auto;
            background-color: $white;
            box-shadow: 0px 0px 6px $mediumGray !important;
            margin: $small-space;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            .card-header-container{
                border-bottom: 1px solid $mediumGray;
                padding: $short-space;
                height: auto;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                .card-title-item{
                    color: $darkGray;
                    text-overflow: ellipsis;
                    height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 90%;
                }
            }
            .card-desc-container{
                padding: $medium-space;
                .card-desc-item{
                    color: $mediumGray;
                    height: 100px;
                    @media only screen and (max-width: $breakpoint-720) {
                        height: auto;
                    }
                }
            }
            .card-action{
                width: 100%;
                padding: $medium-space;
                .card-action-btn{
                    background-color: $secondaryColor;
                    padding: $short-space;
                    border-radius: $short-space;
                    box-shadow: 0px 0px 6px $mediumGray !important;
                    height: 30px;
                    a{
                        color: $white !important;
                        text-decoration: none;
                    }
                    &:hover{
                        box-shadow: 0px 0px 6px $mediumGray !important;
                        background-color: $primaryColor;
                    }
                }
            }
        }
        .upload-button-container{
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            .upload-button{
                background-color: $secondaryColor;
                color: $white;
                input{
                    display: none;
                }
                &:disabled{
                    background-color: $lightGray !important;
                    color: $mediumGray !important;
                }
            }
            .MuiButton-contained.Mui-disabled {
                color: rgba(0, 0, 0, 0.26) !important;
                box-shadow: none;
                background-color: rgba(0, 0, 0, 0.12) !important;
            }
        }
    }
}
.step-buttons{
    width: 100%;
    display: flex;
    margin-top: $short-space-secction;
    flex-flow: row wrap;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-540){
        justify-content: space-around;
    }
}

// third step
.third-step-container{
    background-color: $white !important;
    box-shadow: 0px 0px 6px $mediumGray !important;
    .third-step-content{
        padding: 24px;
        box-shadow: 0px 0px 6px $mediumGray !important;
        .third-title-graduate{
            @extend .bold-serif;
            color: $primaryColorGraduate;
        }
        .third-title-company{
            color: $primaryColor;
        }
        .summary-description{
            @extend .light-serif;
            color: $black;
        }
        .subPreview-container{
            margin: $lmedium-space-secction 0 $small-space 0;
            .preview-subtitle{
                @extend .bold-serif;
                color: $primaryColor;
                margin: 0 $small-space 0 0;
            }
        }
        .preview-edit-btn-graduate{
            background-color: $primaryColorGraduate;
            width: 100%;
            &:hover{
                background-color: $primaryColorGraduateLight;
            }
        }
        .preview-edit-btn-company{
            background-color: $primaryColorCompany;
            width: 100%;
            height: 30px;
            &:hover{
                background-color: $primaryColor;
            }
        }
        .divider-container{
            display: flex;
            justify-content: center;
            align-items: center;
            .divider{
                width: 100%;
                height: 0px;
                margin: 0 $short-space;
            }
        }
        .card-container{
            height: auto;
            background-color: $white;
            box-shadow: 0px 0px 6px $mediumGray !important;
            margin: $small-space;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            .card-header-container{
                border-bottom: 1px solid $mediumGray;
                padding: $short-space;
                height: auto;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                .card-title-item{
                    @extend .regular-serif;
                    color: $darkGray;
                    text-overflow: ellipsis;
                    height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 90%;
                }
            }
            .card-desc-container{
                padding: $medium-space;
                .card-desc-item{
                    color: $mediumGray;
                    height: 100px;
                    @media only screen and (max-width: $breakpoint-720) {
                        height: auto;
                    }
                }
            }
            .card-action{
                width: 100%;
                padding: $medium-space;
                .card-action-btn{
                    background-color: $secondaryColor;
                    padding: $short-space;
                    border-radius: $short-space;
                    box-shadow: 0px 0px 6px $mediumGray !important;
                    height: 30px;
                    a{
                        color: $white !important;
                        text-decoration: none;
                    }
                    &:hover{
                        box-shadow: 0px 0px 6px $mediumGray !important;
                        background-color: $primaryColor;
                    }
                }
            }
        }
    }
}

//recaptcha
.recaptcha-container{
    display: flex;
    justify-content: flex-start;
    background-color: transparent !important;
    @media only screen and (max-width: $breakpoint-540){
        justify-content: center
    }
    .recaptcha-widget{
        box-shadow: 0px 0px 6px $mediumGray !important;
        background-color: $white;
        margin-top: $small-space;
        padding: $short-space;
        .reset-recaptcha-btn{
            font-size: $font-size-h7;
            @extend .regular-serif;
            margin-top: $short-space;
            background-color: $grayRecaptcha;
        }
    }
}

.icon-alert-graduate{
    transform: scale(1.5);
    color: $primaryColorGraduate;
    margin: $small-space;
}
.icon-alert-company{
    transform: scale(1.5);
    color: $primaryColorCompany;
    margin: $small-space;
}

// policy modal
.policy-dialog-container{
    .policy-dialog-header{
        margin: 0;
        padding-bottom: 0;
    }
    .policy-dialog-content{
        height: 350px;
        overflow-y: hidden;
        padding: 8px 50px !important;
        @media only screen and (max-width: $breakpoint-540){
            height: auto;
        }
        .policy-dialog-desc{
            @extend .regular-serif;
            color: $black;
            @media only screen and (max-width: $breakpoint-540){
                font-size: $font-size-h7;
            }
        }
        .policy-text-content{
            margin-top: $small-space;
            height: 140px;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $lightGray;
            .text-content-dialog{
                font-size: $font-size-h6;
                @extend .light-serif;
                height: 100%;
                padding: $small-space $big-space;
                color:$black;
                @media only screen and (max-width: $breakpoint-540){
                    font-size: $font-size-h7;
                }
            }
            .text-dialog-title{
                font-family: $Ancizar-bold-serif !important;
            }
        }

        .policy-text-contentJobBank{
            margin-top: $small-space;
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $lightGray;
            .text-content-dialog{
                font-size: $font-size-h6;
                @extend .light-serif;
                height: 100%;
                padding: $small-space $big-space;
                color:$black;
                @media only screen and (max-width: $breakpoint-540){
                    font-size: $font-size-h7;
                }
            }
            .text-dialog-title{
                font-family: $Ancizar-bold-serif !important;
            }
        }
    }
    .policy-dialog-actions{
        padding: 14px 50px !important;
        .check-dialog{
            font-family: $Ancizar-bold-serif;
            font-display: auto;
            font-size: $font-size-h7;
            color: $primaryColor;
        }
        .policy-button{
            background-color: $secondaryColor;
            color: $white;
            width: 100%;
            &:disabled{
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }
    }
}

#menu-laboralSector{
    .MuiMenu-paper{
        width: 150px;
        overflow-x: auto;
        @media only screen and (max-width: $breakpoint-540){
            width: 280px;
        }
        ul{
            width: fit-content !important;
        }
    }
}