@import '../../../../index.scss';

.roles-management-container-filters{
    width: 100%;
    .register-user-btn-container{
      display: flex;
      justify-content: flex-start;
      margin-top: $small-space;
      .register-user-btn{
        background-color: $warningColor;
        width: 180px;
        color: $black;
        border: 1px solid $black;
        &:hover {
          background-color: $orangeColor !important;
        }
      }
    }
    .roles-container-btn-search{
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $breakpoint-540) {
        justify-content: center;
      }
      .search-btn{
          display: flex;
          justify-content: flex-start;
          background-color: $primaryColorGraduate;
          color: $white;
          width: 140px;
          margin: $small-space;
          &:disabled {
            background-color: $softGray !important;
            color: $mediumGray !important;
          }
      }
      .clear-btn{
        background-color: $primaryError;
        color: $white;
        width: 140px;
        margin: $small-space;
        &:disabled {
          background-color: $softGray !important;
          color: $mediumGray !important;
        }
      }
    }
}

.roles-container-list{
    background-color: $lightGreen;
    margin-top: $small-space;

    .title-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: $medium-space;
    }

    .roles-table-container{
        background-color: $white;
        padding: $short-space;

        .header-table-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            .search-container-outer {
              width: 50%;

              @media only screen and (max-width: $breakpoint-960) {
                width: 100%;
              }
            }

            .results-found-container {
              text-align: right;
              margin: $short-space 0;
              @media only screen and (max-width: $breakpoint-960) {
                width: 50%;
                text-align: start;
              }
              @media only screen and (max-width: $breakpoint-540) {
                width: 100%;
              }
              .results-found-text{
                color: $mediumGray;
              }
            }
        }
    }
}