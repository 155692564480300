@import '../../../index.scss';

.admin-tabs{
    flex-grow: 1;
    width: 100%;
    background-color: $softGray !important;
    .appbar-management{
        background-color: $softGray !important;
        padding-top: 30px;
        max-width: $breakpoint-1280 !important;
        margin: 0 auto;
        box-shadow: none !important;
        @media only screen and (max-width: $breakpoint-720){
            padding-right: 25px;
            padding-left: 25px;
        }
        .tabs-management{
            .Mui-selected{
                font-family: $Ancizar-bold-serif;
                color: $secondaryColor;
                background-color: $lightGray !important;
                box-shadow: 4px 2px 6px $mediumGray !important;
                z-index: 1;
                border-bottom: 5px solid $primaryColor !important;
            }
            .MuiTabScrollButton-root{
                color: $secondaryColor;
                width: 5%;
                @media only screen and (max-width: $breakpoint-720){
                    width: 10%;
                }
            }
        }
        .tabs-management-admin{
            a{
                text-decoration: none;
                font-size: $font-size-h5;
                @extend .regular-serif;
                color: $secondaryColor;
                max-width: 25%;
                border: 1px solid $mediumGray;
                border-radius: 5px 5px 0 0;
                background-color: $white !important;
                margin: 1px 0;
                @media only screen and (max-width: $breakpoint-720){
                    font-size: $font-size-h7;
                    max-width: 264px;
                }
                .MuiTab-wrapper{
                    width: 370px;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    @media only screen and (max-width: $breakpoint-720){
                        width: 150px;
                    }
                    .MuiSvgIcon-root{
                        margin: $short-space;
                    }
                }
            }
        }
        .tabs-management-company, .tabs-management-graduate{
            a{
                text-decoration: none;
                font-size: $font-size-h5;
                @extend .regular-serif;
                color: $secondaryColor;
                max-width: 33%;
                border: 1px solid $mediumGray;
                border-radius: 5px 5px 0 0;
                background-color: $white !important;
                margin: 1px 0;
                @media only screen and (max-width: $breakpoint-720){
                    font-size: $font-size-h7;
                    max-width: 264px;
                }
                .MuiTab-wrapper{
                    width: 370px;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    @media only screen and (max-width: $breakpoint-720){
                        width: 150px;
                    }
                    .MuiSvgIcon-root{
                        margin: $short-space;
                    }
                }
            }
        }
    }

    .tab-panel-container{
        background-color: $white !important;
        .MuiBox-root{
            margin: 0;
            padding: 0;
            .MuiGrid-spacing-xs-1{
                width: 100%;
                margin: 0;
            }
        }
    }
}