@import '../../../index.scss';

.description-container {
  margin-top: $medium-space;

  .description-title {
    @extend .bold-serif;
    color: $primaryColor;
  }
}

.graduate-report-container {
  margin: $medium-space 0 !important;

  .title-container-report {
    background-color: $secondaryColor;

    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .report-container {
    margin-bottom: 10px;
    border: 1px solid $secondaryColor;
    border-radius: $short-space;

    .report-content {
      padding: $medium-space $small-space-secction;

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
      }

      .line {
        border-bottom: 1px solid $softGray;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .report-state-text {
        @extend .bold-serif;
        color: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .report-state-options {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .radio-container {
          .radio-btn {
            color: $primaryColor;
            margin-left: 0;
            margin-right: 40px;

            @media only screen and (max-width: $breakpoint-540) {
              margin-right: 0;
            }

            .MuiTypography-body1 {
              @extend .regular-serif;
              font-size: $font-size-h5 !important;
            }

            .color-btn {
              color: $secondaryColor;
            }
          }
        }
      }
    }

    .no-container {
      display: none;
    }
  }

  .report-container2 {
    margin-bottom: 10px;
    border: 1px solid $secondaryColor;
    border-radius: $short-space;
    .date-content {
      padding: $medium-space $small-space-secction;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
      }
    }
    .report-state-text {
      @extend .bold-serif;
      color: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .apply-filters-button{
    background-color: $secondaryColor;
    color: $white;
    margin: $small-space;
    &:hover{
        background-color: $primaryColor;
    }
    &:disabled{
        background-color: $lightGray !important;
        .apply-filters-text{
            color: $mediumGray !important;
        }
    }
    .apply-filters-text{
        @extend .regular-serif;
        color: $white;
    }
  }

}

.graduate-report-container2 {
  margin: $medium-space 0 !important;
  border: 1px solid $primaryColor;
  border-radius: $short-space;

  .title-container-report2 {
    background-color: $primaryColor;

    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .report-content2 {
    padding: $medium-space $small-space-secction;

    .field-box-margin {
      margin-top: $medium-space !important;
      margin-bottom: $medium-space !important;

      .field-box-title {
        color: $primaryColor;
        @extend .bold-serif;
        margin-bottom: 10px;
      }
    }

    .report-state-options2 {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .radio-container {
        .radio-btn {
          color: $primaryColor;
          margin-left: 0;
          margin-right: 40px;

          @media only screen and (max-width: $breakpoint-540) {
            margin-right: 0;
          }

          .MuiTypography-body1 {
            @extend .regular-serif;
            font-size: $font-size-h5 !important;
          }

          .color-btn {
            color: $secondaryColor;
          }
        }
      }
    }
  }

}

.button-download-report-container {
  justify-content: flex-end;

  @media only screen and (max-width: $breakpoint-540) {
    justify-content: center;
  }

  .generate-report-btn {
    background-color: $secondaryColor;
    color: $white;
    margin-right: $short-space;
    padding: $small-space $small-space-secction;
    margin: $short-space;
    width: 150px;
    &:hover {
      background-color: $primaryColor;
    }
    &:disabled {
      background-color: $softGray !important;
      color: $mediumGray !important;
    }
  }
}
.information-result-report-section {
  align-items: center;
  color: $primaryColorGraduate;

  .total-results-section {
    @extend .bold-serif;
    color: $mediumGray;
    font-size: $font-size-h5;
    margin-left: $short-space;
  }
}