@import '../../../index.scss';

//recaptcha
.recaptcha-container {
  background-color: $white;
  margin-top: $small-space;
  padding: $short-space;

  .reset-recaptcha-btn {
    font-size: $font-size-h7;
    @extend .regular-serif;
    margin-top: $short-space;
    background-color: $grayRecaptcha;
  }
}


.landing-consult-container{
  margin: 0;
  padding: 0;
  // height: 50vh;
  min-width: 0;
  flex-flow: row wrap;
  @media only screen and (max-width: $breakpoint-960) {
    height: auto;
  }

  .first-part{
    @media only screen and (max-width: $breakpoint-960) {
      justify-content: center;
      align-items: center;
    }
    .person-img {
      width: 170px;
      height: 200px;
      border-radius: 0 30px 0 30px;
      margin: 42px 0 0 42px;

      @media only screen and (max-width: $breakpoint-960) {
        margin: 0;
        margin-top: $medium-space;
      }
    }
    .text-container{
      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center;
        align-items: center;

      }

      .title-container {
        @extend .regular-serif;
        color: $primaryColor;
        line-height: $small-space-secction;
        margin-top: 125px;
        margin-left: $medium-space;
        @media only screen and (max-width: $breakpoint-960) {
          font-size: $font-size-h2 !important;
          margin-top: 12px;
          text-align: center;
        }
      }

      .consult-text {
        @extend .regular-serif;
        margin-top: $small-space-secction;
        margin-left: $medium-space;
        color: $primaryColor;
        letter-spacing: 0px;
        line-height: $small-space-secction;
      }
    }
  }

  .container-btn{
      align-items: flex-start;
      margin-top: 25px;
      margin-left: 222px;
      margin-bottom: $small-space-secction;
      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center;
        align-items: center;
        margin-left: 0;
      }
      .consult-btn {
        background-color: $primaryColorGraduate;
        color: $white !important;
        &:hover {
          background-color: $primaryColorGraduateLight !important;
      }
    }

  }

  .general-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: $short-space-secction;
  }

  .general-link{
    @extend .bold-serif;
    border-radius: 10px 0 0 0;
    color: $primaryColor;
    padding-left: $medium-space;
    margin: 10px 0 10px 0;
    @media only screen and (max-width: $breakpoint-960) {
      font-size: $font-size-h2 !important;
    }
  }
  .first-link{
    border-left: solid 15px $primaryColorCompany;
  }
  .second-link {
    border-left: solid 15px $primaryColorGraduate;
  }
  .third-link {
    border-left: solid 15px $warningColor;
  }
  .fourth-link {
    border-left: solid 15px $warningColor;
  }
  .more-info-container {
    padding-left: 222px;
    margin-bottom: $small-space-secction;
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: $breakpoint-960) {
      text-align: center;
      padding-left: 0;
    }
  }

  .more-info-link {
    font-size: $font-size-h6;
    @extend .regular-serif;
    color: $primaryColor;
    padding-top: $short-space;
    padding-left: $short-space;
    width: 100%;
    height: auto;
    margin-bottom: $small-space;
  }
}
