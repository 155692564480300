@import '../../../index.scss';

.show-filters-applied-container{
    margin: $short-space auto !important;
    ul{
        box-shadow: none;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        padding-bottom: $short-space;
        border-bottom: 1px solid $primaryColor;
        width: 100%;
        .chip-styles{
            font-size: $font-size-h6;
            @extend .regular-serif;
            border-radius: 4px;
            margin:  $short-space $short-space $short-space 0;
            background-color: $primaryColor;
            color: $white;
            .MuiChip-deleteIcon{
                color: $white;
            }
        }
    }
}