@import '../../../index.scss';

.unenroll-dialog-container {
    .unenroll-dialog-header{
        margin: 0;
        padding-bottom: 0;
        color: $primaryColor;
    }
    .unenroll-dialog-content{
        overflow-y: hidden;
        padding: 8px 50px !important;
        
        @media only screen and (max-width: $breakpoint-540){
            height: auto;
            padding: 8px 30px !important;
        }
        .text-content-dialog{
            @extend .regular-serif;
            height: 100%;
            padding: $small-space $big-space;
            color:$black;
            @media only screen and (max-width: $breakpoint-540){
                font-size: $font-size-h7;
            }
        }
    }
    .unenroll-dialog-actions{
        padding: 14px 50px !important;
        @media only screen and (max-width: $breakpoint-540){
            padding: 8px 30px !important;
        }

        .back-button {
            background-color: transparent;
            border: 1px solid $primaryError;
            color: $primaryError;
            width: 100%;
        }
        .accept-button{
            background-color: $secondaryColor;
            color: $white;
            width: 100%;
            &:disabled{
                background-color: $lightGray !important;
                color: $mediumGray !important;
            }
        }
    }
}