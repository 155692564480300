@import '../../../index.scss';

.simple-filter-container{
    margin: $medium-space 0 !important;
    border: 1px solid $primaryColor;
    box-shadow: 2px 2px 6px $mediumGray !important;
    border-radius: $short-space;
    padding: $medium-space $small-space-secction;
    .simple-filter-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .simple-filter-options{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .group-checkbox{
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            .label-checkbox{
                width: 150px;
                .MuiFormControlLabel-label{
                    font-size: $font-size-h5;
                    @extend .regular-serif;
                    color: $primaryColor;
                }
                .Mui-checked{
                    color:$primaryColorCompany;
                }
            }
        }
    }
}