@import '../../../index.scss';

.change-container {
    background-color: $white !important;
    color: $primaryColor;

    .title-container {
        display:flex;
        @media only screen and (max-width: $breakpoint-540) {
            justify-content: center;
        }
        .security-icon {
            font-size: $icon-size-small;
            margin: $short-space $medium-space;
            @media only screen and (max-width: $breakpoint-320) {
                margin: 0 auto;
            }
        }
        .title-text {
            @extend .bold-serif;
            @media only screen and (max-width: $breakpoint-320) {
                text-align: center;
            }
        }
        .line-title {
            border-top: 2px solid $softGray;
            opacity: 1;
            margin-top: $short-space-secction;
            margin-left: $big-space;
            @media only screen and (max-width: $breakpoint-320) {
                margin-left: 0px;
                justify-content: center;
            }
        }
    }

    .password-container {
        @extend .regular-serif;
        margin: $big-space 0;
        @media only screen and (max-width: $breakpoint-960) {
            margin: 0px;
        }
        .error-password{
            color: $primaryError;
        }
        .policies-container {
            display: flex;
            flex-direction: column;
            margin: $short-space-secction $lmedium-space-secction;
            @media only screen and (max-width: $breakpoint-960) {
                margin: $small-space 0px;
            }
            .policiesterm-box {
                display: flex;
                margin: $short-space;
            }
            .errorOutline-text {
                margin-left: $short-space;
                font-family: $Ancizar-bold-serif !important;
            }
        }
    }

    .inputPassword-container {
        display: flex;
        flex-direction: column;
        margin-bottom: $small-space-secction;
        .inputPassword-title {
            margin-bottom: $medium-space;
            margin-top: $small-space-secction;
        }
    }

    .submit-button {
        padding: $short-space 0px;
        .sub-btn {
            background-color: $secondaryColor;
            color: $white;
            margin-bottom: $small-space;
            &:disabled{
                    background-color: $lightGray !important;
                    color: $mediumGray !important;
                }
        }
    }

}