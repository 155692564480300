@import '../../../index.scss';


.section-form {
  padding-right: 10px;

  @media only screen and (max-width: $breakpoint-960) {
    padding-right: 0;
  }

  .section-container2 {
    border: 1px solid $primaryColor;
    border-radius: $short-space;
    margin-bottom: $medium-space;

    .title-container {
      background-color: $primaryColor;
      margin: 0 !important;
      border-bottom: 1px solid $white;

      .title {
        @extend .bold-serif;
        color: $white;

        @media only screen and (max-width: $breakpoint-960) {
          font-size: $font-size-h3;
        }
      }
    }

    .section-content2 {
      padding: $medium-space $small-space-secction;
      background-color: $white;

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .field-box-margin {
        padding: $small-space $small-space-secction;
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .field-box-text {
          @extend .regular-serif;          
          margin-bottom: 10px;
        }
      }
    }

  }
}


.expand_icon {
  color: $white !important;
}

.associate-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .associate-button {
    background-color: $warningColor !important;
    color: $black !important;
    width: 253px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .associate-disabled-button {
    background-color: $softGray !important;
    color: $black !important;
    width: 253px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}



.buttons-container-section {
  margin-top: $short-space-secction;
  

  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
    display: flex;
    flex-flow: inherit;
  }

  .left-buttons {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }

    .cancel-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }
  }

  .right-buttons {
    justify-content: flex-end;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }

    .save-button {
      width: 160px;
      height: 32px;
      background-color: $secondaryColor;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }

      &:disabled {
        background-color: $softGray !important;
      }
    }

    .delete-question-button {

      border: 1px solid #FF4E15;      
      margin: 5px;
      color: #FF4E15;
      
      @extend .regular-serif;
      font-size: $font-size-h5;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }


    }    





  }

  .no-button {
    display: none;
  }

  .center-buttons {
    justify-content: center;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }

    .delete-button {
      width: 160px;
      height: 32px;
      color: $grayRecaptcha;
      background-color: $primaryError;
      border: 1px solid $primaryError;
      margin: 5px;
      
      @extend .regular-serif;
      font-size: $font-size-h5;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }

      &:disabled {
        background-color: $softGray !important;
      }
    }
  }

  

}