@import '../../../index.scss';

.job-offers-seacrh-section{
    margin: 0;
    padding: 0;
    height: auto;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/images/temp_imagen.jpg') ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.job-offers-list-section{
    background-color: $white;
    .line{
        border-bottom: 1px solid $softGray;
        margin-bottom: 20px;
    }
}

.img-fluid{
    width: 100%;
}