@import '../../../index.scss';

.subsection-container {
    margin: $small-space 0 !important;
    width: 25% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media only screen and (max-width: $breakpoint-960) {
        width: 50% !important;
    }
    @media only screen and (max-width: $breakpoint-540) {
        width: 100% !important;
    }
    .section-title-complete {
        width: 100%;
        color: $primaryColorGraduate;
        padding-right: $small-space;
    }
    .section-value-complete {
        width: 100%;
        color: $darkGray;
        line-height: 1.2;
    }
}
.subsection-container-100{
    margin: $small-space 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .section-title-complete {
        width: 100%;
        color: $primaryColorGraduate !important;
        padding-right: $small-space !important;
    }
    .section-value-complete {
        width: 100%;
        color: $darkGray !important;
        line-height: 1.2 !important;
    }
}

@media print {
    .subsection-container {
        margin: $small-space 0 !important;
        width: 50% !important;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        .section-title-complete {
            width: 100%;
            color: $primaryColorGraduate !important;
            padding-right: $small-space !important;
        }
        .section-value-complete {
            width: 100%;
            color: $darkGray !important;
            line-height: 1.2 !important;
        }
    }
    .subsection-container-100{
        margin: $small-space 0 !important;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .section-title-complete {
            width: 100%;
            color: $primaryColorGraduate !important;
            padding-right: $small-space !important;
        }
        .section-value-complete {
            width: 100%;
            color: $darkGray !important;
            line-height: 1.2 !important;
        }
    }
}