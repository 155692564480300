@import '../../../index.scss';

.welcome-graduate-section{
    width: 100%;
    background-color: $softGray;
    margin-bottom: 0;

    .welcome-margin {
        max-width: $max-breakpoint-limit;
        margin: 0 auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;

        @media only screen and (max-width: $breakpoint-1125) {
            max-width: 90%;
        }
    }
}