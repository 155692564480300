@import '../../../index.scss';

.success-dialog-container {
  .success-dialog-header {
    margin: 0;
    padding-bottom: 0;
    .success-dialog-title {
      @extend .bold-serif;
      color: $greenSuccess;
    }
  }

  .success-dialog-content {
    overflow-y: hidden;
    padding: 8px 50px !important;

    @media only screen and (max-width: $breakpoint-540) {
      height: auto;
      padding: 8px 30px !important;
    }

    .text-content-dialog {
      @extend .regular-serif;
      height: 100%;
      padding: $small-space $big-space;
      color: $black;
      @media only screen and (max-width: $breakpoint-540) {
        font-size: $font-size-h7;
      }
    }
  }

  .success-dialog-actions {
    padding: 14px 50px !important;

    @media only screen and (max-width: $breakpoint-540) {
      padding: 8px 30px !important;
    }

    .accept-button {
      background-color: $secondaryColor;
      color: $white;
      width: 100%;
      &:disabled {
        background-color: $lightGray !important;
        color: $mediumGray !important;
      }
    }
  }
}