@import '../../../index.scss';

.consult-container {
  background-color: $white !important;
  box-shadow: 2px 2px 6px $mediumGray !important;
  padding: 24px;
  @media only screen and (max-width: $breakpoint-540) {
    padding: $medium-space 0;
  }
  .consult-content {
    padding: 24px 24px 24px 0;
    @media only screen and (max-width: $breakpoint-540) {
      padding: 0;
    }

    .summary-description {
      @extend .regular-serif;
      color: $primaryColor;
      margin: $medium-space 0 $medium-space;
    }

    .number-identification {
      color: $primaryColorCompany;
      margin: $medium-space 0 $medium-space;
    }

    .radio-container{
      .radio-btn{
        @extend .regular-serif;
        color: $primaryColor;
        margin-left: 0;
        margin-right: 60px;
        @media only screen and (max-width: $breakpoint-540) {
          margin-right: 0;
        }
        .color-btn{
          color: $primaryColorCompany;
        }
      }
    }

  }

  .consult-button {
    padding: 24px 24px 24px 0;
    .const-btn {
      background-color: $secondaryColor;
      color: $white !important;
      width: 120px;
      margin-left: 60px;
      &:hover {
        background-color: $primaryColor !important;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
    }
  }

  .line {
    border-bottom: 1px solid $softGray !important;
    margin-left: 60px;
    margin-right: 60px;
  }

  //recaptcha
  .recaptcha-container {
    display: flex;
    justify-content: flex-start;
    background-color: transparent !important;
    margin-left: 60px;
    @media only screen and (max-width: $breakpoint-540) {
      justify-content: center;
      margin-left: 0;
    }

    .recaptcha-widget {
      box-shadow: 2px 2px 6px $mediumGray !important;
      background-color: $white;
      margin-top: $small-space;
      padding: $short-space;

      .reset-recaptcha-btn {
        font-size: $font-size-h7;
        @extend .regular-serif;
        margin-top: $short-space;
        background-color: $grayRecaptcha;
      }
    }
  }

}

