@import '../../../index.scss';

.title-additional-info-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    color: $primaryColor;
    .title-additional-text {
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        align-items: center;
    }

    .line-section {
        border-bottom: 1px solid $primaryColor;
    }
}
.line-section-divider{
    width: 100%;
    border-bottom: 1px solid $softGray !important;
}

@media print {
    .title-additional-info-container {
        display: flex !important;
        flex-flow: row wrap !important;
        justify-content: space-between !important;
        align-items: center !important;
        color: $primaryColor !important;
        .title-additional-text {
            display: flex !important;
            justify-content: center !important;
            flex-flow: row nowrap !important;
            align-items: center !important;
        }
        .line-section {
            border-bottom: 1px solid $primaryColor !important;
        }
    }
    .line-section-divider{
        width: 100%;
        border-bottom: 1px solid $mediumGray !important;
    }
}