@import '../../../index.scss';

.recover-container {
    background-color: $white !important;
    color: $primaryColor;
    justify-content: center;
    text-align: center;
    .recover-box-container {
        padding: $small-space-secction $lmedium-space-secction;
        border: 1px solid $softGray;
        border-radius: 5px;
        opacity: 1;
        @media only screen and (max-width: $breakpoint-540) {
            padding: $short-space-secction;
        }
        .recover-title {
            @extend .light-serif;
            color: $primaryColor;
            padding: $small-space;
            line-height: 1.1;
            @media only screen and (max-width: $breakpoint-720) {
                font-size: 28px;
            }
        }
        .box-recover-container {
            border: 1px solid $primaryColor;
            border-radius: 5px;
            justify-content: center;
            margin-bottom: 15px;
            margin-left: 40px;
            margin-right: 40px;
            @media only screen and (max-width: $breakpoint-720) {
                margin: 15px 0px;
            }

            .box-text {
                @extend .bold-serif;
                opacity: 1;
                margin: $medium-space;
                line-height: 1.1;
            }
            .url {
                color: $primaryColor;
                text-decoration: underline;
            }
            .description-text {
                @extend .regular-serif;
                color: $black;
                padding: $small-space;
                margin-bottom: $small-space;
            }
        }
        .security-icon {
            font-size: $icon-size-big;
            @media only screen and (max-width: $breakpoint-720) {
                font-size: $icon-size-medium;
            }
        }
        .buttonsColors {
            margin: 0px 40px;
            margin-bottom: $small-space-secction;
        }
        .submit-button {
            padding: $medium-space;
            justify-content: center;
            .sub-btn {
                background-color: $secondaryColor;
                color: $white;
                margin-bottom: $small-space;
            }
        }
    }
}