@import '../../../index.scss';

#shortDescription-cell {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  @extend .regular-serif;
  white-space: pre-line !important;
  line-height: 1.2 !important;
  padding: 10px 0 !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: $primaryColor!important;
}