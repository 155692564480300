@import '../../../index.scss';

.filter-box{
  color: $white;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: $breakpoint-960) {
    justify-content: center;
  }
  .search-container-inner{
    justify-content: flex-end !important;
  }
  .search-box {
    padding-right: 5px;
    padding-left: 5px;
    @media only screen and (max-width: $breakpoint-960) {
      width: 100%;
    }
  }
  .container-buttons{
    align-items: center;
    margin: $small-space 0;
    @media only screen and (max-width: $breakpoint-960) {
      width: 100%;
    }
    .order-text{
      font-size: $font-size-h5;
      @extend .light-serif;
      margin-left: 10px;
    }
    .order-buttons-container{
      display: inline-block;
      .order-btn{
        width: 120px;
        margin: 5px;
        background-color: $primaryColorGraduateLight;
        height: 25px;
        color: $primaryColor;
        border-radius: 30px;
      }
    }
  }
}