@import '../../../index.scss';

.register-container {
    background-color: $primaryColorGraduateLight;
    border-left: solid 15px $primaryColorGraduate;
    margin: 0;
    padding: 0;
    // height: 40vh;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $breakpoint-800) {
        height: auto;
    }
    .btn-container{
        margin-bottom: $lmedium-space-secction;

        .button-register {
            color: $white;
            background-color: $primaryColorGraduate;
            margin-top: $medium-space;
            margin-bottom: $medium-space;
            border: solid 1px transparent;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0) !important;;
            font-family: 'Ancizar Sans bold';
            &:hover {
                background-color: #9bca3c;
                color: $white;
                border: solid 1px white;
                text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;;
            }
        }
    }

    .register-text {
        width: 60%;
        padding: 20px;
    }
}
