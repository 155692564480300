@import '../../../index.scss';

.summary-description-container{
    background-color: $grayRecaptcha;
    margin-top: $short-space-secction;
    margin-left: $small-space-secction;
    margin-right: $small-space-secction;
    padding: $short-space-secction;
    border: 2px solid $softGray;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    @media only screen and (max-width: $breakpoint-960) {
        margin-left: $short-space;
        margin-right: $short-space;
    }
    @media only screen and (max-width: $breakpoint-720) {
        margin-left: 0px;
        margin-right: 0px;
    }
    .summary-description-content {
        width: 100%;
        .line-title {
            border-top: 2px solid $softGray;
            opacity: 1;
        }
        .title-text {
            @extend .bold-serif;
            color: $black;
        }
        .text-details-text {
            color: $darkGray
        }
        .title-detail-text {
            @extend .bold-serif;
            color: $primaryColor;
            margin: $short-space 0px;
        }
        .submit-button {
            text-align: center;
            .apply-offer-btn {
                background-color: $primaryColorGraduateLight;
                color: $primaryColor;
                margin-top: $large-space-secction;
                width: auto;
                border: 1px solid $primaryColorGraduate;
                border-radius: 5px;
                &:hover{
                    background-color: $primaryColorGraduate;
                }
            }
            .disabled-offer-btn {
                background-color: $softGray;
                color: $darkGray;
                margin-top: $short-space;
                width: 200px;
                border: 1px solid $darkGray;
                border-radius: 5px;
                &:hover{
                    background-color: $darkGray;
                }
            }
            .retry-offer-btn {
                background-color: transparent;
                color: $primaryError;
                margin-top: $short-space;
                width: auto;
                border: 1px solid $primaryError;
                border-radius: 5px;
                &:hover{
                    background-color: $lightGray;
                }
            }
        }
    }

}