@import '../../../index.scss';

.news-events-card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding:  $big-space;
    .news-events-card-section{
        padding: 0px $medium-space;
        .news-events-card-header {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            @media only screen and (max-width: $breakpoint-540) {
                margin-top: $big-space;
            }
            .news-event-header-title{
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                color: $primaryColor;
                .grade-icon{
                    margin-right: $short-space;
                }
                .news-events-title {
                    @extend .bold-serif;
                    font-size: $font-size-h3;
                    text-align: left;
                    margin-top: 10px;
                    @media only screen and (max-width: $breakpoint-540) {
                        font-size: $font-size-h5;
                    }
                }
            }
            .second-title-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $primaryColorCompany;
                @extend .regular-serif;
                font-size: $font-size-h6;
                .icon-news-events{
                    margin-right: $short-space;
                }
            }
        }
        .divider-accordion {
            width: 100%;
            height: 0px;
            margin:  $small-space 0px;
            border: 1px solid $softGray;
        }
    }
    .news-events-header-dates{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        color: $primaryColorCompany;
        @extend .regular-serif;
        font-size: $font-size-h6;
        .news-events-title-header{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .date-events-container{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .icon-news-events {
                    margin-right: $short-space;
                }
            }
            .time-events-container{
                margin: 0 $small-space;
            }
        }
    }
    .card-body-new-event-content {
        .summary-description {
            margin: $short-space 0;
            @extend .light-serif;
            color: #504F4F;
        }
    }
    .btn-event-new-container {
        display: flex;
        justify-content: flex-start;
        margin-top: $small-space;
        @media only screen and (max-width: $breakpoint-720) {
            justify-content: center;
        }
        .content-btn {
            background-color: $secondaryColor;
            width: 120px;
            color: $white;
            .btn-type-text{
                text-transform: lowercase;
                margin-left: 5px;
            }
        }
    }
}