@import '../../../index.scss';

.preview-detail-container {
  background-color: $white;
  border: 1px solid $primaryColor;
  border-radius: 5px;
  padding: $big-space $small-space-secction;
  @media only screen and (max-width: $breakpoint-960) {
    padding: $medium-space $big-space;
  }
  .img-container {
    width: 100%;
    margin: 0 auto;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media only screen and (max-width: $breakpoint-720) {
      height: 200px;
    }
    @media only screen and (max-width: $breakpoint-540) {
      height: 150px;
    }
    .img {
      width: 100%;
      object-fit: cover;
    }
  }

  .preview-summary-container {
    align-items: flex-start;
    margin: $big-space 0;
    @media only screen and (max-width: $breakpoint-540) {
      margin: 20px;
    }
    @media only screen and (max-width: $breakpoint-960) {
      display: flex;
      flex-flow: column nowrap;
    }

    .preview-category {
      @extend .regular-serif;
      color: $primaryColor;
      background-color: $warningColor;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: $short-space;

      .category-icon {
        font-size: $icon-size-small;
        margin: 0 $short-space;
      }
    }

    .preview-title {
      @extend .bold-serif;
      color: $primaryColor;
      margin: $medium-space 0 ;
      line-height: 1.1 !important;
      @media only screen and (max-width: $breakpoint-960) {
        font-size: $font-size-h2;
        margin: $small-space 0 ;
      }
    }

    .preview-short-description {
      @extend .light-serif;
      color: $primaryColor;
      margin-top: $small-space;
      line-height: 1.1 !important;
      @media only screen and (max-width: $breakpoint-960) {
        font-size: $font-size-h4;
      }
    }

    .info-container{
      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center !important;
      }
    .info-event-container {
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #D7D7D7;
      border-radius: 5px;
      opacity: 1;
      @media only screen and (max-width: $breakpoint-1125) {
        max-width: 350px;
        justify-content: center !important;
      }

      .event-summary-content {
        margin-top: 20px;
        margin-left: 10px;

        .event-title {
          @extend .bold-serif;
          color: $primaryColor;
          margin-bottom: 5px;
          margin-top: 5px;
          @media only screen and (max-width: $breakpoint-540) {
            font-size: $font-size-h6;
          }
        }

        .event-label {
          background-color: $white;
          color: $secondaryColor;
          @extend .regular-serif;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: $short-space;

          .date-icon {
            font-size: $icon-size-small;
            margin: 0 $short-space;
          }
          @media only screen and (max-width: $breakpoint-540) {
            font-size: $font-size-h6;
          }
        }
      }

      .enroll-container {
        justify-content: center;
        .enroll-btn {
          height: 40px;
          width: 200px;
          margin-top: 10px;
          margin-bottom: 20px;
          color: $white;
          background-color: $secondaryColor;
          &:hover {
            background-color: $primaryColor !important;
          }
        }
      }
    }
    }
  }
  .registered-number-event{
    // display: flex;
    justify-content: space-between;
    color: $primaryColor;
    @media only screen and (max-width: $breakpoint-540) {
      justify-content: center !important;
    }
    .download-list-button{
      text-decoration: none;
      .download-list-btn {
        padding: $small-space $small-space-secction;
        color: $white;
        background-color: $secondaryColor;
  
        &:hover {
          background-color: $primaryColor !important;
        }
      }
    }
  }
  .line {
    border-bottom: 1px solid $softGray;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.btn-detail-container{
  justify-content: flex-end;
  margin-top: 20px;
  .continue-button{
    width: 120px;
    color: $white;
    background-color: $secondaryColor;
    &:hover {
      background-color: $primaryColor !important;
    }
  }
}

.no-container{
  display: none !important;
}

.title-section-line {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;

  .group-icon {
    margin: 10px;
    color: $primaryColor;
  }

  .event-title {
    @extend .bold-serif;
    font-size: $font-size-h2;
    color: $primaryColor;
    @media only screen and (max-width: $breakpoint-720) {
      font-size: $font-size-h3;
    }
  }
}

.lines {
  border-bottom: 1px solid $softGray;
  margin-bottom: 20px !important;
}

.preview-description-html{
  width: 100%;
  margin-top: $small-space;
  margin-bottom: 30px !important;
  margin-right: 10px !important;
  margin-left: $short-space !important;
  // quill styles
  .ql-align-center{
    text-align: center;
  }
  .ql-align-right{
    text-align: right;
  }
  .ql-align-left{
    text-align: left;
  }
}
.preview-event-description-html{
width: 100%;
margin-top: $small-space;
margin-bottom: 30px !important;
margin-right: 10px !important;
margin-left: $short-space !important;

img {
  height: auto;
  max-width: 850px;
}

// quill styles
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}
}