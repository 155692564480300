@import '../../../../index.scss';

.applicants-offer-view{
    background-color: $white;
    .title-select-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin:  $medium-space 0px;
        color:$primaryColor;
        .person-select-icon {
            font-size: $icon-size-small;
            margin-right: $medium-space;
        }
    }
    .line{
        border-bottom: 1px solid $softGray;
        margin-bottom: 20px;
    }
}