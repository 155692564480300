@import '../../index.scss';

.profile-info-container {
  .profile-accordion-container{
    margin-bottom: 20px;
    border: 1px solid $primaryColor;
    .accordion-summary{
      color: $primaryColor;
      .MuiSvgIcon-root{
        color: $primaryColor;
      }
      .personIcon{
        margin: 10px;
      }
      .rol{
        font-family: $Ancizar-regular-serif;
      }
    }
  }
  .profile-accordion-details{
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 50px !important;
  }
}