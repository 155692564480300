@import '../../../index.scss';

.surveys-filter-container {
  margin: $big-space 0 !important;
  border: 1px solid $primaryColor;
  box-shadow: 2px 2px 6px $mediumGray !important;
  border-radius: $short-space;
  padding: $big-space $lmedium-space-secction;

  .surveys-filter-text {
    @extend .bold-serif;
    color: $primaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .surveys-title {
    margin-bottom: $small-space;
  }

  .surveys-filter-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .radio-container {
      .radio-btn {
        color: $primaryColor;
        margin-left: 0;
        margin-right: 40px;
        @media only screen and (max-width: $breakpoint-540) {
          margin-right: 0;
        }

        .MuiTypography-body1 {
          @extend .regular-serif;
          font-size: $font-size-h5 !important;
        }

        .color-btn {
          color: $secondaryColor;
        }
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: $big-space;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: center;
    }

    .search-btn {
      display: flex;
      justify-content: space-between;
      background-color: $primaryColorGraduate;
      color: $white;
      width: 110px;

      &:hover {
        background-color: $primaryColorGraduateLight;
      }
    }
  }
}