@import '../../../index.scss';

.margin-collapse {
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-alert{
  flex-flow: row;
  @media only screen and (max-width: $breakpoint-540) {
    flex-flow: column;
  }
}