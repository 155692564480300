@import '../../../index.scss';

.desc-offer-box{
    .desc-offer-container {
        width: 100%;
        color:$primaryColor;
        .line-description-title {
            border-top: 1px solid $primaryColor;
            opacity: 1;
            @media only screen and (max-width: $breakpoint-320) {
                margin-left: 0px;
                justify-content: center;
            }
        }
        .second-container-text{
            .text-description-details {
                @extend .light-serif;
                color:$darkGray;
            }
            .title-detail-description-text {
                @extend .bold-serif;
                color: $primaryColor;
                margin: $big-space 0px;
                margin-right: $short-space !important;
            }
            .line-description-text{
                border-top: 1px solid $softGray;
                opacity: 1;

                @media only screen and (max-width: $breakpoint-320) {
                    margin-left: 0px;
                    justify-content: center;
                }
            }
            .list-detail-text{
                li{
                    margin-bottom: $small-space-secction;
                }
                li span {
                    color: $darkGray;
                }
                .subtitle-description-text{
                    font-family: $Ancizar-bold-serif;
                    margin-right: $short-space;
                }
            }
            .italic-description-text{
                @extend .bold-serif;
                color:$darkGray
            }
            .submit-button {
                padding-top: $medium-space;
                justify-content: flex-start;
                .apply-offer-btn {
                    background-color: $primaryColorGraduateLight;
                    color: $primaryColor;
                    margin-top: $short-space;
                    width: 200px;
                    border: 1px solid $primaryColorGraduate;
                    border-radius: 5px;
                    &:hover{
                        background-color: $primaryColorGraduate;
                    }
                }
                .disabled-offer-btn {
                    background-color: $softGray;
                    color: $darkGray;
                    margin-top: $short-space;
                    width: 200px;
                    border: 1px solid $darkGray;
                    border-radius: 5px;
                    &:hover{
                        background-color: $darkGray;
                    }
                }
                .retry-offer-btn {
                    background-color: transparent;
                    color: $primaryError;
                    margin-top: $short-space;
                    width: 200px;
                    border: 1px solid $primaryError;
                    border-radius: 5px;
                    &:hover{
                        background-color: $lightGray;
                    }
                }
            }
        }
    }
}