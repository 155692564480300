@import '../../../index.scss';

.surveys-form{
  padding-right: 10px;
  @media only screen and (max-width: $breakpoint-960) {
    padding-right: 0;
  }

  .surveys-container2 {

    border: 1px solid $primaryColor;
    border-radius: $short-space;
    margin-bottom: $medium-space;

    .title-container {
      background-color: $primaryColor;
      margin: 0 !important;
      .title {
        @extend .bold-serif;
        color: $white;
        padding: $medium-space $small-space-secction;

        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }

    .surveys-content2 {
      padding: $medium-space $small-space-secction;

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .field-box-text {
          @extend .regular-serif;
          color: $primaryColor;
          margin-bottom: 10px;
        }
      }

      .form-button-container{
        
        justify-content: flex-start;
        margin-bottom: 10px;
        .form-button{
          background-color: $secondaryColor;
          @extend .regular-serif;
          font-size: $font-size-h5;
          color: $white;
        }
      }
    }
  }
}

.link-container {
  margin-top: 20px !important;
  border: 1px solid $softGray;
  @media only screen and (max-width: $breakpoint-960) {
    margin-right: 0 !important;
    justify-content: center !important;
  }

  .copy-text {
    padding: $medium-space $small-space-secction;
    color: $primaryColor;
    @extend .regular-serif;
    @media only screen and (max-width: $breakpoint-720) {
      padding: 0 !important;
    }
  }
  .alert-text{
    padding: $medium-space $small-space-secction;
    color: $primaryColor;
  }

  .copy-container{
    padding: $medium-space $small-space-secction;
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: $breakpoint-720) {
      flex-flow: column nowrap;
      justify-content: center !important;
    }

    .copy-title {
      color: $primaryColor;
      @extend .bold-serif;
      margin-bottom: 10px;
    }

    .button-cont {
      justify-content: center;
      align-items: center;
      display: flex;
      @media only screen and (max-width: $breakpoint-720) {
        flex-flow: row nowrap;
      }
      .copy-btn {
        background-color: $secondaryColor;
        @extend .regular-serif;
        font-size: $font-size-h5;
        margin-top: 30px;
        height: 32px;
        width: 150px;
        color: $white;
      }
      .copy-area {
        display: flex;
        align-items: center;
        @media only screen and (max-width: $breakpoint-720) {
          justify-content: center !important;
        }
        .copy-text-cont{
          @media only screen and (max-width: $breakpoint-720) {
            justify-content: center !important;
          }
          .copy-feedback {
            margin-left: 1rem;
            @extend .regular-serif;
            font-size: $font-size-h5;
            color: $primaryColor;
            padding-top: 10px;
            visibility: hidden;
          }
          
          .copy-feedback.active {
            visibility: visible;
          }
        }
      }
    }
  }
}

.buttons-container-survey {
  margin-top: $short-space-secction;
  padding: 10px 10px 10px 0;
  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .left-buttons{
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .cancel-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }
    .delete-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryError;
      border: 1px solid $primaryError;
      margin: 0 10px;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px ;
      }
    }
  }

  .right-buttons {
    justify-content: flex-end;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .save-button {
      width: 150px;
      height: 32px;
      background-color: $secondaryColor;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
      &:disabled {
        background-color: $softGray !important;
      }
    }
  }

  .no-button {
    display: none;
  }

}