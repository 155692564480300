@import '../../index.scss';

.profile-company-container {
    margin: $medium-space 0 !important;
    border: 1px solid $primaryColor;
    border-radius: $short-space;
    padding: $medium-space $small-space-secction;

    .line {
        border-bottom: 1px solid $softGray;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .fixed-text {
            font-size: $font-size-h5;
            @extend .regular-serif;
            color: $black;
        }
    }
}
.profile-company-container-danger {
    margin: $medium-space 0 !important;
    border: 1px solid $primaryError;
    border-radius: $short-space;
    padding: $medium-space $small-space-secction;

    .line {
        border-bottom: 1px solid $softGray;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .fixed-text {
            font-size: $font-size-h5;
            @extend .regular-serif;
            color: $black;
        }
    }
    .checkbox-dangerZone-container {
        margin-bottom: $small-space;
        .checkbox {
            margin: 0 !important;
            padding: 0;
            .MuiFormControlLabel-label {
                color: $primaryColor;
                @extend .bold-serif;
                font-size: $font-size-h5;
            }
            .Mui-checked {
                color: $secondaryColor;
            }
        }
    }
}

.update-container {
    margin-top: $short-space-secction !important;
    margin-bottom: $short-space-secction !important;
    text-align: end;

    @media only screen and (max-width: $breakpoint-540) {
        text-align: center;
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
    }

    .update-btn {
        background-color: $secondaryColor;
        color: $white;
        width: 120px;

        &:disabled {
            background-color: $softGray !important;
            color: $mediumGray !important;
        }

        &:hover {
            background-color: $primaryColor;
        }
    }
}

.photography-content-container {
    .card-container {
        height: auto;
        background-color: $white;
        box-shadow: 2px 2px 6px $mediumGray !important;
        margin: $small-space;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;

        .card-header-container {
            border-bottom: 1px solid $mediumGray;
            padding: $short-space;
            height: auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .card-title-item {
                color: $darkGray;
                text-overflow: ellipsis;
                height: 20px;
                white-space: nowrap;
                overflow: hidden;
                width: 90%;
            }
        }

        .card-desc-container {
            padding: $medium-space;

            .card-desc-item {
                color: $mediumGray;
                height: 100px;

                @media only screen and (max-width: $breakpoint-720) {
                    height: auto;
                }
            }
        }

        .card-action {
            width: 100%;
            padding: $medium-space;

            .card-action-btn {
                background-color: $secondaryColor;
                padding: $short-space;
                border-radius: $short-space;
                box-shadow: 2px 2px 6px $mediumGray !important;
                height: 30px;

                a {
                    color: $white !important;
                    text-decoration: none;
                }

                &:hover {
                    box-shadow: 2px 2px 6px $mediumGray !important;
                    background-color: $primaryColor;
                }
            }
        }
    }

    .upload-button-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: $short-space;

        .upload-button {
            width: 200px;
            background-color: $secondaryColor;
            color: $white;
            padding: $small-space 0;
            @media only screen and (max-width: $breakpoint-540) {
                width: 150px;
            }
            input {
                display: none;
            }
            &:hover {
                background-color: $primaryColor!important;
            }
        }

        .MuiButton-contained.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12) !important;
        }
    }

    .buttons-save-container {
        justify-content: flex-start;
        padding: $medium-space !important;

        @media only screen and (max-width: $breakpoint-720) {
            justify-content: center;
        }

        .save-button {
            background-color: $secondaryColor;
            color: $white;
            margin-right: $short-space;
            padding: $small-space $small-space-secction;
            margin: $short-space;
            width: 150px;

            &:hover {
                background-color: $primaryColor;
            }

            &:disabled {
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }
    }
}