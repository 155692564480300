@import '../../index.scss';

.new-events-list-section {
    margin: 0;
    padding: 0;
    height: auto;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-color: $white;
    .title-news-events-section {
        margin-bottom: $big-space;
        color: $primaryColor;
        .news-events-title {
            @extend .bold-serif;
            @media only screen and (max-width: 580px) {
                font-size: $font-size-h2;
            }
        }
    }

    .admin-news-events-tabs {
        flex-grow: 1;
        width: 100%;
        padding: $big-space 0px;

        .nav-news-events-management {
            background-color: $white !important;
            padding: $small-space-secction 0px;
            max-width: $breakpoint-1280 !important;
            box-shadow: none !important;
            .border-icon{
                border-right: 1px solid $white !important;
            }
            
            @media only screen and (max-width: $breakpoint-720) {
                    padding-right: 25px;
                    padding-left: 25px;
                }
                
                .tabs-management {
                    button {
                    @extend .light-serif;
                    font-size: $font-size-h4;
                    text-transform: capitalize;
                    color: $white;
                    border-color: $secondaryColor;
                    background-color: $secondaryColor;
                    
                    @media only screen and (max-width: $breakpoint-720) {
                        font-size: $font-size-h5;
                        max-width: 264px;
                    }

                    .MuiTab-wrapper {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        // margin-left: $small-space;
                        
                        @media only screen and (max-width: $breakpoint-720) {
                            width: 150px;
                        }

                        .MuiSvgIcon-root {
                            margin-right: $short-space;
                            margin-top: $short-space;
                        }
                    }
                }
                .MuiTab-labelIcon {
                    min-height: 10px; 
                    min-width: 120px;
                    padding: $short-space $short-space-secction ;
                }
                .MuiTabs-indicator{
                    background-color: transparent;
                }

                .Mui-selected {
                    background-color: $primaryColor !important;
                    border-right: 2px solid $white;
                    border-left: 2px solid $white;
                }

                .MuiTabScrollButton-root {
                    color: $secondaryColor;
                    width: 0%;

                    @media only screen and (max-width: $breakpoint-720) {
                        width: 10%;
                    }
                }
            }
        }
        .tab-news-events-panel-container {
            background-color: $white !important;

            .MuiBox-root {
                margin: 0;
                padding: $small-space 0;

                .MuiGrid-spacing-xs-1 {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

}