@import '../../../index.scss';

.menu-container{
  justify-content: flex-end;

  li{
    width: 100%;
  }

  a{
    width: 100%;
  }

  .icon-menu-user{
    margin-left: 10px;
  }

  .line-menu-user {
    border-top: 2px solid $primaryColor;
  }

  .item-menu-user{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px;
    padding: 8px 0;
    @extend .regular-serif;
    font-size: $font-size-h5;
    white-space: break-spaces;
    text-align: end;
  }

  .item-menu-color{
    color: $primaryColorCompany;
  }

  .bg-role-selected{
    background-color: $primaryColorCompanyLight;
    &:hover{
      background-color: $primaryColor;
      color: $white;
    }
  }

  .logout{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: $primaryColor;
    margin: 0 10px;
    padding: 8px 0;
    @extend .bold-serif;
    font-size: $font-size-h5;
  }
}

.header-container{
  height: auto;
  width: 100%;
  border-bottom: 1px solid $mediumGray !important;
  background-color: $white !important;
  .header-box-container{
    max-width: $max-breakpoint-limit !important;
    margin: 0 auto !important;
    @media only screen and (max-width: $breakpoint-1125) {
      max-width: 90% !important;
    }
    .general-box {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    .btn-header-menu{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-flow: row;
      .button-login {
        background-color: $white;
        color: $primaryColor;
        margin-top: $short-space;
        border-radius: 30px;
        margin: 0 10px;
        height: 40px;
        border: solid 1px $primaryColor;
        box-sizing: border-box;
        font-size: $font-size-h6;
        @extend .regular-serif;
        &:hover{
          background-color: $primaryColor;
          color: white;
        }
      }
      .line {
        border-bottom: 2px solid $black;
        margin-bottom: 20px;
      }
    }
    .MuiIconButton-root{
      width: 32px!important;
      height: 32px!important;
      align-self: center;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30)!important;
    }
    .button-icons{
      color: $primaryColor !important;
      transition: all 200ms ease-out!important;
      &:hover{
        background-color: $primaryColor !important;
        color: white!important;
      }
    }
  }
  a:focus {
    outline: none !important;
    text-decoration: none !important;
  }
}

.image-logo{
  width: 192px;
  @media only screen and (max-width: $breakpoint-800) {
    margin-left: 0;
  }
  @media only screen and (max-width: $breakpoint-540) {
    width: 150px;
    margin-left: 0;
  }
}
