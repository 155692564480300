@import '../../../index.scss';

.line-one{
    background-color: $secondaryColor;
}
.line-two{
    background-color: $primaryColorGraduate;
}
.line-three{
    background-color: $warningColor;
}
.line-fourth{
    background-color: $warningColor;
}
.line-height{
    height: 7px;
}