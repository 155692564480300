@import '../../index.scss';

.state-filters-curriculum-container {
    max-width: $max-breakpoint-limit !important;
    margin: 0 auto !important;

    @media only screen and (max-width: $breakpoint-1125) {
        max-width: 90% !important;
    }

    .container-curriculum-buttons {
        justify-content: space-around;

        @media only screen and (max-width: $breakpoint-540) {
            justify-content: center;
            margin: 0px;
        }

        .button-curriculum-definition {
            background-color: $secondaryColor;
            border-radius: 30px;
            border: 1px solid $white;
            color: $white;
            margin: $short-space $big-space;
            padding: $short-space $short-space-secction;

            @media only screen and (max-width: $breakpoint-540) {
                width: 350px;
            }
        }

        .button-curriculum-definition-active {
            border-radius: 30px;
            border: 1px solid $white;
            background-color: $white;
            color: $primaryColor;
            margin: $short-space $big-space;
            padding: $short-space $small-space-secction;

            @media only screen and (max-width: $breakpoint-540) {
                width: 350px;
            }
        }

    }
}

.curriculum-form-info-container {
    .curriculum-section-accordion-container {
        margin-bottom: $big-space !important;
        border: 1px solid $primaryColor;

        .accordion-summary {
            color: $white;
            background-color: $primaryColor;

            .MuiSvgIcon-root {
                color: $white;
                font-size: 26px;
            }

            .summary-title {
                font-family: $Ancizar-bold-serif;
                font-size: 26px;

                @media only screen and (max-width: $breakpoint-540) {
                    font-size: 20px;

                }
            }
        }
    }

    .curriculum-information-accordion-details {
        overflow-y: auto;
        overflow-x: hidden;
        padding: $small-space $small-space-secction !important;

        .information-box-margin {
            margin: $medium-space;

            .information-box-section {
                margin-bottom: $big-space;
                color: $primaryColorGraduate;

                .title-box-text {
                    margin: $short-space 0px;
                }

                .card-information-container {
                    border-radius: 5px;
                    box-shadow: 2px 2px 6px $mediumGray !important;
                    padding: $medium-space $big-space;
                }

                .divider-secction-accordion {
                    width: 100%;
                    height: 0px;
                    margin: 0px;
                    border: 1px solid $softGray;
                }

                .visibility-curriculum-container {
                    color: $primaryColor;

                    @media only screen and (max-width: $breakpoint-540) {
                        justify-content: center;
                        padding: $small-space;
                    }
                }

            }

            .checkbox-curriculum-container {
                margin-bottom: $small-space;

                .checkbox {
                    margin: 0 !important;
                    padding: 0;

                    .MuiFormControlLabel-label {
                        color: $primaryColorGraduate;
                        @extend .bold-serif;
                        font-size: $font-size-h5;
                    }

                    .Mui-checked {
                        color: $secondaryColor;
                    }
                }
            }

            .checkbox-top-curriculum-container {
                @extend .bold-serif;
                display: flex;
                align-content: space-between;
                align-items: flex-start;

                .checkbox-top {
                    margin: 0 $small-space !important;

                    .MuiFormControlLabel-label {
                        color: $primaryColorGraduate;
                        font-family: $Ancizar-bold-serif;
                        font-size: $font-size-h5;
                    }

                    .Mui-checked {
                        color: $secondaryColor;
                    }
                }
            }

            .add-container {
                text-align: end;
                
                @media only screen and (max-width: $breakpoint-540) {
                    text-align: center;
                }
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .add-btn {
                    height: 40px;
                    background-color: $secondaryColor;
                    color: $white;
                    
                    @media only screen and (max-width: $breakpoint-540) {
                        margin-top: 0;
                        margin-bottom: 20px;
                    }

                    &:disabled {
                        background-color: $softGray !important;
                        color: $mediumGray !important;
                    }
                }
            }
            .detail-container{
                justify-content: space-between !important;
                border: 1px solid $softGray;
                background-color: $grayRecaptcha;
                margin: $short-space 0;
                .detail-description{
                  color: $darkMediumGray;
                  margin: 5px;
                }
                .del-btn{
                  color: $primaryError;
                  background-color: $grayRecaptcha;
                  border: 1px solid $primaryError;
                  margin: 5px;
                }
              }
            .detail-add-container {
                display: flex;
                justify-content: space-between !important;
                border: 1px solid $softGray;
                background-color: $grayRecaptcha;
                border-radius: 5px;
                margin: $short-space 0;
                padding: $short-space;

                .detail-add-description {
                    color: $darkMediumGray;
                    margin: 5px;
                }

                .delete-container {

                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: flex-end;

                    .delete-btn {
                        color: $primaryError;
                        background-color: $grayRecaptcha;
                        border: 1px solid $primaryError;
                        margin: 5px;
                    }
                }
            }

            .button-action {
                width: 100%;

                .detail-action-btn {
                    background-color: $secondaryColor;
                    padding: $short-space $small-space;
                    margin-bottom: $medium-space;
                    color: $white;

                    &:hover {
                        background-color: $primaryColor;
                    }
                }
            }
        }

        .photography-content-container {
            .card-container {
                height: auto;
                background-color: $white;
                box-shadow: 2px 2px 6px $mediumGray !important;
                margin: $small-space;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;

                .card-header-container {
                    border-bottom: 1px solid $mediumGray;
                    padding: $short-space;
                    height: auto;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;

                    .card-title-item {
                        color: $darkGray;
                        text-overflow: ellipsis;
                        height: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 90%;
                    }
                }

                .card-desc-container {
                    padding: $medium-space;

                    .card-desc-item {
                        color: $mediumGray;
                        height: 100px;

                        @media only screen and (max-width: $breakpoint-720) {
                            height: auto;
                        }
                    }
                }

                .card-action {
                    width: 100%;
                    padding: $medium-space;

                    .card-action-btn {
                        background-color: $secondaryColor;
                        padding: $short-space;
                        border-radius: $short-space;
                        box-shadow: 2px 2px 6px $mediumGray !important;
                        height: 30px;

                        a {
                            color: $white !important;
                            text-decoration: none;
                        }
                        &:hover {
                            box-shadow: 2px 2px 6px $mediumGray !important;
                            background-color: $primaryColor;
                        }
                    }
                }
            }
        }
    }

    .complementary-text-container {
        color: $primaryColor;
        margin: $short-space 0;
    }

    .step-curriculum-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: $medium-space 0 !important;
        align-items: center;

        @media only screen and (max-width: $breakpoint-720) {
            justify-content: space-around;
            padding: 0 !important;
        }

        .back-button {
            background-color: $primaryColorGraduateLight;
            color: $white;
            padding: $small-space $small-space-secction;
            width: 150px;
            height: 38px;
        }
    }

    .buttons-curriculum-form-container {
        justify-content: flex-end;
        padding: $medium-space !important;

        @media only screen and (max-width: $breakpoint-720) {
            justify-content: center;
        }

        .save-button {
            background-color: $secondaryColor;
            color: $white;
            margin-right: $short-space;
            padding: $small-space $small-space-secction;
            margin: $short-space;
            width: 150px;

            &:hover {
                background-color: $primaryColor;
            }

            &:disabled {
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }

        .next-button {
            background-color: $white;
            color: $primaryColor;
            border: 1px solid $primaryColor;
            padding: $small-space $small-space-secction;
            margin: $short-space 0;
            width: 150px;

            &:hover {
                background-color: $secondaryColor;
            }
        }
    }
}

.no-container {
    display: none;
}