@import '../../../index.scss';

.news-event-container{
  margin: 0;
  padding: 0;
  // height: 60vh;
  min-width: 0;
  flex-flow: row wrap;
  .left-side{
    background-color: $primaryColorGraduateLight;
    @media only screen and (max-width: $breakpoint-960) {
      justify-content: center;
      align-items: center;
    }
    .top-side{
      margin-bottom: 40px;
      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center;
        align-items: center;
      }
      .company-img {
        width: 170px;
        height: 200px;
        border-radius: 0 30px 0 30px;
        margin: 42px 0 0 42px;
        
        @media only screen and (max-width: 865px) {
          margin: 0;
          margin-top: $medium-space;
        }
      }
      
      .text-container {
        @media only screen and (max-width: 865px) {
          justify-content: center;
          align-items: center;
        }

        .title-container {
          @extend .light-serif;
          margin-left: $medium-space;
          color: $primaryColor;
          letter-spacing: 0px;
          margin-top: 125px;

          @media only screen and (max-width: 865px) {
            font-size: $font-size-h2 !important;
            margin: 20px 100px 20px 100px;
            text-align: center;
          }
        }

        .summary-text {
          margin-top: $short-space-secction;
          margin-left: $medium-space;
          @extend .regular-serif;
          color: $primaryColor;
          letter-spacing: 0px;
          line-height: $small-space-secction;
        }
      }
    }
    .bottom-side{
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 30px;
      background-color: $primaryColorGraduate;

      .bottom-title{
        @extend .light-serif;
        color: $white;
      }
      .column-container{
        margin-top: 30px;
        justify-content: space-evenly;
        color: $white;
        
        .un-radio{
          @extend .light-serif;
          border-left: solid 10px $primaryColorCompany;
          padding-left: 10px;
          padding-top: 8px;
          height: 40px;
          margin-bottom: 10px;
        }
        .un-periodico{
          @extend .light-serif;
          border-left: solid 10px $primaryColor;
          padding-left: 10px;
          padding-top: 8px;
          height: 40px;
          margin-bottom: 10px;
        }
        .un-tv{
          @extend .light-serif;
          border-left: solid 10px $warningColor;
          padding-left: 10px;
          padding-top: 8px;
          height: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .right-side{
    .admin-tabs{
      flex-grow: 1;
      width: 100%;
      background-color: $white !important;
    }
    .appbar-management{
      background-color: $white !important;
      // .tabs-management button {
      //   background-color: $white !important;
      //   color: $warningColor !important;
      //   border: 1px solid $warningColor !important;
      // }
    }
  }
}