@import '../../../index.scss';

.login-admin-container {
  margin: 0;
  padding: 0;
  height: 75vh;

  @media only screen and (max-width: $breakpoint-540) {
    margin-bottom: $big-space;
  }

  .login-admin-container-img {
    background-image: url('../../../assets/images/IMG_LOGIN_EMPRESAS.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .login-admin-container-box {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px 40px;
    margin-bottom: -24px;

    .login-admin-header {
      .MuiGrid-item {
        flex-basis: 100% !important;
      }

      .title-login-admin {
        @extend .regular-serif;
        color: $primaryColor;
        width: 100%;
        font-weight: normal;
        letter-spacing: 0px;
      }

      .subtitle {
        flex-basis: auto;
        @extend .regular-serif;
        .subtitle-login-admin {
          color: $primaryColor;
          margin-right: $short-space;
        }
        .subtitle-login-admin-info {
          width: 80%;
          color: $softGray;
          border-left: 1px solid $softGray;
          padding-left: $short-space;
        }
      }
    }

    .MuiGrid-item {
      flex-basis: auto;
    }

    .login-admin-container-form {
      display: flex;
      flex-flow: column nowrap;
      margin-top: $short-space-secction;

      .button-login-admin {
        @extend .regular-serif;
        color: $white;
        background-color: $secondaryColor;
        margin-top: $medium-space;
        margin-bottom: $short-space;
        &:disabled {
          background-color: $softGray !important;
          color: $mediumGray !important;
        }
      }
    }

    .login-admin-container-terms {
      padding: $short-space 0;
      margin-left: -24px;
      display: flex;
      flex-flow: row nowrap;

      .policies-icon {
        color: $primaryColor;
      }
    }

    .policies-link {
      @extend .bold-serif;
      color: $primaryColor;
      font-size: $font-size-h7;
      padding-top: $short-space;
      padding-left: $short-space;
      width: 100%;
      height: auto;
      margin-bottom: $small-space;
    }

  }
}