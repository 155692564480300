@import '../../../index.scss';

.offer-card-container{
    width: 100%;
    padding: $small-space $big-space;
    .offer-card-header{
        justify-content: space-between;
        .name-header{
          padding: $short-space 0;
          display: flex;
          align-items: center;
          color: $primaryColorCompany;
        }
        .date-header{
          color: $primaryColorCompany;
          padding: $short-space 0;
          display: flex;
          align-items: center;
          @media only screen and (max-width: $breakpoint-540) {
            align-items: flex-start;
          }
        }
      }
      .divider-accordion {
        width: 100%;
        height: 0px;
        margin: 0;
        border: 1px solid $softGray;
      }
      .card-body-content{
        .summary-title{
          margin: $short-space 0;
          color: $primaryColor;
          width: 100%;
          text-align: left;
          @media only screen and (max-width: $breakpoint-540) {
            font-size: $font-size-h3;
          }
        }
        .summary-description{
          color: $darkMediumGray;
          margin: $short-space 0;
        }
        .summary-description2 {
          color: $primaryColor;
          text-align: start;
          margin: $short-space 0;
        }
        .container-date{
          margin: $short-space 0;
          justify-content: space-between;
          .summary-description3 {
            color: $primaryColor;
            display: flex;
            align-items: center;
            margin: $short-space 0;
          }
        }
    }
}