@import '../../../../index.scss';

.question-container {
  margin: $medium-space 0 !important;

  .line {
    border-bottom: 1px solid $softGray;
    margin-bottom: 20px;
  }

  .btn-container {
    margin: 15px 0;

    .add-profile-btn {
      background-color: $primaryColorGraduate;
      color: $white;
      @extend .bold-serif;
      font-size: $font-size-h5;

      @media only screen and (max-width: 580px) {
        font-size: $font-size-h7;
      }

      &:disabled {
        background-color: $softGray !important;
      }
    }
  }
}

.title-section-line {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;

  .group-icon {
    margin: 10px;
    color: $primaryColor;
  }

  .question-title {
    @extend .bold-serif;
    color: $primaryColor;

    @media only screen and (max-width: 580px) {
      font-size: $font-size-h3;
    }
  }
}

.option-answer-container {

  .option-btn {
    background-color: $white;
    color: $secondaryColor;

    input {
      display: none;
    }

    &:disabled {
      background-color: $lightGray !important;
      color: $mediumGray !important;
    }
  }
}