@import '../../../../index.scss';




.banner-container{
  margin: 20px;

  .start-buton{
    background-color: $primaryColor;
  }
}
.survey-container {
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid $primaryColor;

  .survey-header{
    background-color: $primaryColor;
    border-radius: 5px 0 5px 0;

  
  }
  .title-section-line {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    .group-icon {
      margin: 10px;
      color: $white;
    }
  
    .survey-title {
      @extend .bold-serif;
      color: $white;
      margin: auto;
  
  
      @media only screen and (max-width: 580px) {
        font-size: $font-size-h3;
      }
    }
  } 

}