@import '../../../index.scss';

.darkBackground{
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    opacity: 0.6;
    .loading-lock-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        .loading-lock-text{
            @extend .bold-serif;
            color: $white;
            text-align: center;
        }
    }
}