@import '../../index.scss';

.success-message-box{
    display: flex;
    align-items: center;
    .check-Outlined-icon{
        margin-right: $small-space;
        margin-left: $lmedium-space-secction;
    }
    .check-Outlined-text{
        font-family: $Ancizar-regular-serif!important;
    }
}
.step-change-password-back {
    background-color: transparent !important;
    color: $primaryColorGraduate !important;
    width: 120px;
    margin: $big-space $lmedium-space-secction !important;
    @media only screen and (max-width: $breakpoint-720) {
        margin: $big-space $small-space-secction;
    }
    &:hover {
        background-color: $primaryColorGraduateLight !important;
    }
}