@import '../../../index.scss';

.card-related-column{
  margin: 10px;
  @media only screen and (max-width: $breakpoint-800) {
    justify-content: center;
  }
  .card-related-box{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    max-width: 345px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin: 10px;
    .card-related-box-info{
      .type-description{
        margin-top: -30px;
        padding-right: 10px;
        position: absolute;
        @extend .regular-serif;
        color: $primaryColor;
        background-color: $warningColor;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: $short-space;
        .category-icon {
          font-size: $icon-size-small;
          margin: 0 $short-space;
        }
      }
      .card-related-content{
        padding: 0 $small-space;
        .card-title{
          @extend .bold-serif;
          color: $primaryColor;
          margin-top: 5px;
          margin-bottom: 5px;
          @media only screen and (max-width: $breakpoint-800) {
            font-size: $font-size-h3;
          }
        }
        .date-section{
          @extend .regular-serif;
          color: $secondaryColor;
          margin-top: 4px;
          margin-bottom: 4px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: $short-space 0;
          .date-icon {
            font-size: $icon-size-small;
            margin: 0 $short-space 0 0;
          }
        }
        .summary-container{
          margin-top: 10px;
          margin-bottom: 20px;
          .card-summary-news{
            @extend .regular-serif;
            color: $primaryColor !important;
            margin-top: 5px;
            @media only screen and (max-width: $breakpoint-800) {
              font-size: $font-size-h6;
            }
          }
        }
      }
    }
    .card-related-box-actions{
      .card-related-button{
        justify-content: flex-end !important;
        padding: 0px $short-space !important;
        .view-button{
          width: 100%;
          color: $white;
          height: 40px;
          background-color: $secondaryColor;
          margin-bottom: 10px;
          
        }
      }
    }
  }
}
