@import '../../../index.scss';

.search-container-inner{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin: $short-space 0;
    .search-title{
        @extend .regular-serif;
        margin-right: $medium-space;
        color: $darkGray;
        padding: $short-space 0;
    }
    .search-box{
        width: 300px;
        padding: $short-space 0;
        .MuiInputBase-root{
            height: 35px;
            background-color: $white;
        }
    }
}