@import '../../../index.scss';

.description-container {
  margin-top: $medium-space;

  .description-title {
    @extend .bold-serif;
    color: $primaryColor;
  }
}

.company-report-container {
  margin: $medium-space 0 !important;

  .title-container-report {
    background-color: $secondaryColor;

    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .report-container {
    margin-bottom: 10px;
    border: 1px solid $secondaryColor;
    border-radius: $short-space;

    .report-content {
      padding: $medium-space $small-space-secction;

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
      }

      .line {
        border-bottom: 1px solid $softGray;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .report-state-text {
        @extend .bold-serif;
        color: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .report-state-options {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .radio-container {
          .radio-btn {
            color: $primaryColor;
            margin-left: 0;
            margin-right: 40px;

            @media only screen and (max-width: $breakpoint-540) {
              margin-right: 0;
            }

            .MuiTypography-body1 {
              @extend .regular-serif;
              font-size: $font-size-h5 !important;
            }

            .color-btn {
              color: $secondaryColor;
            }
          }
        }
      }
    }

    .no-container {
      display: none;
    }
  }
}

.company-report-container2 {
  margin: $medium-space 0 !important;
  border: 1px solid $primaryColor;
  border-radius: $short-space;

  .title-container-report2 {
    background-color: $primaryColor;

    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .report-content2 {
    padding: $medium-space $small-space-secction;

    .field-box-margin {
      margin-top: $medium-space !important;
      margin-bottom: $medium-space !important;

      .field-box-title {
        color: $primaryColor;
        @extend .bold-serif;
        margin-bottom: 10px;
      }
    }

    .report-state-options2 {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .radio-container {
        .radio-btn {
          color: $primaryColor;
          margin-left: 0;
          margin-right: 40px;

          @media only screen and (max-width: $breakpoint-540) {
            margin-right: 0;
          }

          .MuiTypography-body1 {
            @extend .regular-serif;
            font-size: $font-size-h5 !important;
          }

          .color-btn {
            color: $secondaryColor;
          }
        }
      }
    }
  }
}