@import '../../../index.scss';

.load-area-container{
    width: 100%;
    align-items: center;
    .load-area-icon:first-child span{
        display: flex;
        margin: 0 auto;
    }
    .loading-area-text{
        font-family: $Ancizar-bold-serif;
        text-align: center;
    }
}