@import '../../../../index.scss';

.trainingevent-create-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .create-button {
    background-color: $warningColor !important;
    color: $black !important;
    width: 253px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

.trainingevent-list-container {
  background-color: #94fdbc;

  .list-table-container {
    background-color: $white;
    border: 1px solid $softGray;
    border-radius: 5px;
    padding: $small-space $medium-space;

    .title-trainingevent-table-management {
      @extend .bold-serif;
      color: $primaryColor;
      margin: $big-space $short-space;
    }

    .header-table-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .search-trainingevent-container {
        width: 50%;

        @media only screen and (max-width: $breakpoint-960) {
          width: 100%;
        }

      }
    }

    .list-view {
      margin: 5px !important;

      @media only screen and (max-width: $breakpoint-540) {
        margin: 0 !important;
      }
    }
  }
}