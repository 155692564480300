@import '../../../index.scss';

.back-button-applicants-section {
    background-color: $primaryColor;
    .back-btn-container {
        .step-back-offers {
            background-color: transparent;
            color: $white !important;
            border: 1px solid $white !important;
            width: 250px;
            &:hover {
                background-color: $primaryColorGraduate !important;
                border: 1px solid $primaryColorGraduate !important;
            }
        }
    }
}

 .line-final-form {
     border-bottom: 1px solid $primaryColor;
     margin: $big-space 0px !important;
 }

.action-curriculum-view-buttons {
    width: 100%;
    display: flex;
    margin-top: $short-space!important;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: $breakpoint-540) {
        justify-content: space-around;
    }
    .back-btn {
        background-color: transparent;
        border: 1px solid $primaryColorGraduate;
        color: $primaryColorGraduate;
        width: 120px;
        margin: 10px 0;
        &:hover {
            background-color: $primaryColorGraduate;
            border: 1px solid $primaryColorGraduateLight;
            color: $white;
        }
    }
    .print-btn {
        background-color: $secondaryColor;
        color: $white;
        width: 120px;
        margin: 10px 0;
        &:hover {
            background-color: $primaryColor;
        }
    }
}

.section-photography{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    .photography-curriculum-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 20%;
        @media only screen and (max-width: $breakpoint-720) {
            width: 100%;
        }
        .curriculum-photography-description {
            padding-right: $short-space !important;
            width: 160px;
            object-fit: cover;
        }
    }
    .special-padding-cv{
        padding-left: 15px;
        width: 80%;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: $breakpoint-960) {
            padding-left: 40px;
        }
        @media only screen and (max-width: $breakpoint-800) {
            padding-left: 60px;
        }
        @media only screen and (max-width: $breakpoint-720) {
            padding-left: 0;
            width: 100%;
        }
    }
}
.only-data{
    padding-left: 0;
    width: 100%;
}

@media print{
    .section-photography{
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 100% !important;
        .photography-curriculum-container {
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            .curriculum-photography-description {
                padding-right: $short-space !important;
                width: 160px !important;
                object-fit: cover !important;
            }
        }
        .special-padding-cv{
            padding-left: 15px !important;
            width: 80% !important;
            @media only screen and (max-width: $breakpoint-960) {
                padding-left: 40px !important;
            }
            @media only screen and (max-width: $breakpoint-800) {
                padding-left: 60px !important;
            }
            @media only screen and (max-width: $breakpoint-720) {
                padding-left: 0 !important;
            }
        }
    }
    .only-data{
        padding-left: 0;
        width: 100%;
    }
}

.main-container-cv-actions{
    max-width: $max-breakpoint-limit !important;
    margin: 0 auto !important;
    margin-bottom: $small-space-secction !important;
    display: flex !important;
    flex-flow: column nowrap !important;
    justify-content: space-evenly !important;
    @media only screen and (max-width: $breakpoint-1125){
        max-width: 90% !important;
    }
}
