@import '../../../index.scss';

.question-form{
  padding-right: 10px;
  @media only screen and (max-width: $breakpoint-960) {
    padding-right: 0;
  }

  .question-container2 {

    border: 1px solid $primaryColor;
    border-radius: $short-space;
    margin-bottom: $medium-space;

    .title-container {
      background-color: $primaryColor;
      margin: 0 !important;
      .title {
        @extend .bold-serif;
        color: $white;
        padding: $medium-space $small-space-secction;

        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }


    .btn-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      width: 100%;

      @media only screen and (max-width: $breakpoint-720) {
        justify-content: center;
      }
  
      .create-button {
        display: flex;
        justify-content: space-between;
        background-color: $warningColor !important;
        color: $black !important;
        width: 200px;
        justify-content: center;
      }
    }

    .question-content2 {
      padding: $medium-space $small-space-secction;

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .question-title {
        color: $primaryColor;
        @extend .regular-serif;
        margin-bottom: 10px;
      }      

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .field-box-text {
          @extend .regular-serif;
          color: $primaryColor;
          margin-bottom: 10px;
        }
      }

      .form-button-container{
        
        justify-content: flex-start;
        margin-bottom: 10px;
        .form-button{
          background-color: $secondaryColor;
          @extend .regular-serif;
          font-size: $font-size-h5;
          color: $white;
        }
      }
    }
  }
}


.buttons-container-question {
  margin-top: $short-space-secction;
  padding: 10px 10px 10px 0;
  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
    display: flex;
    flex-flow: inherit;
  }

  .left-buttons{
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .cancel-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }
    .delete-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryError;
      border: 1px solid $primaryError;
      margin: 0 10px;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px ;
      }
    }
  }

  .right-buttons {
    justify-content: flex-end;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .save-button {
      width: 150px;
      height: 32px;
      background-color: $secondaryColor;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
      &:disabled {
        background-color: $softGray !important;
      }
    }
  }

  .no-button {
    display: none;
  }

}


.custom-stepper {
  
  @media only screen and (min-width: $breakpoint-960) {
    background-color: $stepperBackGround !important;
    margin-right: 5px;
    border-radius: $short-space;
    margin-bottom: $medium-space;

    
  }
  .title-container {
    .title {
      color: $primaryColor;
      padding: $medium-space $small-space-secction 0;
      font-size: $font-size-h5;
      
    }
  }
}