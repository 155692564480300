@import '../../../../index.scss';

.permissions-view-container{
  .title-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: $medium-space 0px;

    .security-icon {
      font-size: $icon-size-small;
      margin-right: $medium-space;
      color: $primaryColor;
    }
  }

  .container-save-permissions{
    display: flex;
    justify-content: flex-end;
    .save-permissions-btn{
      background-color: $primaryColorGraduate;
      color: $white;
      width: 200px;
      &:hover {
        background-color: $primaryColorGraduateLight !important;
      }
    }
  }
}