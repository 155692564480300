@import '../../../index.scss';

.laboral-offer-info-container {
    margin-left: 35px;
    margin-bottom: 20px;
    @media only screen and (max-width: $breakpoint-540) {
        margin: $short-space 0px;
    }
    .summary-offer-accordion-container {
        border: 1px solid $primaryColor;
        width: 80%;
         @media only screen and (max-width: $breakpoint-800) {
             width: 100%
         }
        .accordion-summary {
            color: $white;
            background-color: $primaryColor;
            .MuiSvgIcon-root {
                color: $white;
                font-size: $icon-size-small;
            }
        }
        .description-summary-container {
            padding: $big-space $lmedium-space-secction;
            @media only screen and (max-width: $breakpoint-540) {
                padding: $big-space;
            }
        }
    }

}