@import '../../../index.scss';

.principal-title-container {
    background-color: $primaryColor;
    padding: $short-space $medium-space;
    margin: $medium-space 0px;
    .principal-title-text {
        color: $white;
        @extend .bold-serif;
    }
}


@media print {
    .principal-title-container {
        background-color: $primaryColor !important;
        padding: $short-space $medium-space !important;
        margin: $medium-space 0px !important;
        .principal-title-text {
            color: $white !important;
            font-family: $Ancizar-bold-serif;
        }
    }
}