@import '../../../index.scss';

.title-container-box{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $primaryColor;
    .find-page-icon{
      font-size: $icon-size-medium;
      margin-right: $small-space;
      @media only screen and (max-width: $breakpoint-320) {
        margin-right: 0px;
        font-size: 30px;
      }
    }
}
.summary-container{
  color: $primaryColorCompanyLight;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .icons{
    margin-right: 5px;
  }
  .left-side{
    display: flex;
    flex-flow: row nowrap;
    padding: 0px $lmedium-space-secction;
    @media only screen and (max-width: $breakpoint-720) {
      padding: 0px $short-space

    }
    @media only screen and (max-width: $breakpoint-540) {
      display: flex;
      flex-flow: column nowrap;
    }
    .publish-offer-box{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: $medium-space 0px;
      @media only screen and (max-width: $breakpoint-320) {
       margin: $small-space 0px;
      }
      .text-decoration {
        text-decoration: underline;
      }
      .title-detail{
        @extend .bold-serif;
        margin-right: $short-space;
      }
    }
  }
  .right-side{
    color: $primaryColor;
    text-align: end;
     @media only screen and (max-width: $breakpoint-960) {
       text-align: start;
       padding-left: 0px;
     }

    .close-offer-text{
      margin-right: $small-space !important;
      .calendar-icon{
        font-size: $icon-size-medium;
        margin-right: $short-space;
        @media only screen and (max-width: $breakpoint-320) {
           font-size: $icon-size-medium;
           margin-left: 0px;
         }
      }
      .close-offer-details {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}