@import '../../../index.scss';

.title-section-line {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;

  .group-icon {
    margin-right: 10px;
    color: $primaryColor;
  }

  .prev-survey-title {
    @extend .bold-serif;
    color: $primaryColor;

    @media only screen and (max-width: 580px) {
      font-size: $font-size-h3;
    }
  }
}
.description-container{
  margin-bottom: 20px !important;
  .description{
    @extend .regular-serif;
    color: $black;
  }
}

.finish-title{
  color: $primaryError;
}

.iframe-form{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.not-logged-survey{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: $small-space 0;
  .loggin-survey-btn{
    margin-top: $small-space;
    background-color: $secondaryColor;
    color: $white;
    width: 150px;
    height: 30px;
    &:hover {
      background-color: $primaryColor;
    }
  }
}