@import '../../../index.scss';

.show-filters-container{
    margin: $medium-space auto !important;
    ul{
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        padding-bottom: $short-space;
        border-bottom: 1px solid $primaryColor;
        .chip-styles{
            font-size: $font-size-h6;
            @extend .regular-serif;
            border-radius: 4px;
            margin:  $short-space $short-space $short-space 0;
            background-color: $primaryColor;
            color: $white;
            height: auto;
            padding: 4px 0;
            .MuiChip-deleteIcon{
                color: $white;
            }
        }
    }
    .apply-filters-box{
        margin-top: $small-space;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        .apply-filters-button{
            background-color: $secondaryColor;
            color: $white;
            margin: $small-space;
            &:hover{
                background-color: $primaryColor;
            }
            &:disabled{
                background-color: $lightGray !important;
                .apply-filters-text{
                    color: $mediumGray !important;
                }
            }
            .apply-filters-text{
                @extend .regular-serif;
                color: $white;
            }
        }
    }
}