@import '../../../index.scss';

.subsection-preview{
    margin: $small-space 0 !important;
}
.section-preview-title{
    @extend .regular-serif;
    color: $mediumGray;
    margin-bottom: $short-space !important;
}
.section-preview-value{
    @extend .bold-serif;
    color: $darkMediumGray;
    line-height: 1.2;
}