@import '../GraduateCurriculum.scss';

.typePaes {
    .MuiSelect-selectMenu {
        white-space: normal;
        
    }
    .MuiSelect-outlined.MuiSelect-outlined{
        padding: 10px 10px 10px !important;
    }
    
}
.photography-cv-container {
    padding: $medium-space $short-space ;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;

    .description-documents-text {
        font-display: auto;
        color: $black;
    }

    .card-photography-container {
        height: auto;
        background-color: $white;
        box-shadow: 2px 2px 6px $mediumGray !important;
        margin: $medium-space 0px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;

        .card-header-container {
            border-bottom: 1px solid $mediumGray;
            padding: $short-space;
            height: auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .card-title-item {
                font-display: auto;
                color: $darkGray;
                text-overflow: ellipsis;
                height: 20px;
                white-space: nowrap;
                overflow: hidden;
                width: 90%;
            }
        }

        .card-desc-container {
            padding: $medium-space;
            display: flex;
            justify-content: center;
            align-items: center;

            .curriculum-photography{
                width: 140px;
                object-fit: cover;
            }
        }

        .card-action {
            width: 100%;
            padding: $medium-space;
            .card-action-btn {
                background-color: $secondaryColor;
                border-radius: $short-space;
                box-shadow: 2px 2px 6px $mediumGray !important;
                font-size: $font-size-h6;
                font-family: $Ancizar-regular-serif;
                font-weight: 500;
                line-height: 1.75;
                letter-spacing: 0.02857em;
                padding: $short-space $small-space-secction;
                a {
                    padding: 6px;
                    color: $white !important;
                    text-decoration: none;
                }
                &:hover {
                    box-shadow: 2px 2px 6px $mediumGray !important;
                    background-color: $primaryColor;
                }
            }
        }
    }

    .upload-button-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: $short-space;

        .upload-button {
            width: 200px;
            background-color: $secondaryColor;
            color: $white;
            padding: $small-space 0;
            @media only screen and (max-width: $breakpoint-540) {
                width: 150px;
            }
            input {
                display: none;
            }
            &:hover {
                background-color: $primaryColor!important;
            }
        }

        .MuiButton-contained.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12) !important;
        }
    }
}