@import '../../../index.scss';

.back-offers-btn-container{
    .step-back-offers {
        background-color: transparent;
        color: $white !important;
        border: 1px solid $white !important;
        width: auto;
        &:hover {
            background-color: $primaryColorGraduate !important;
            border: 1px solid $primaryColorGraduate !important;
        }
    }
}