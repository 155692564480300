@import '../../../index.scss';

.data-grid-container{
    width: 100%;
    .MuiDataGrid-iconButtonContainer{
        margin-left: -10px;
    }
    .data-grid-list{
        border: none;
        @media only screen and (max-width: $breakpoint-1125){
            border: 1px solid $lightGray;
        }
        .MuiDataGrid-columnsContainer{
            max-height: 43px !important;
            background-color: $primaryColor;
            .MuiIconButton-label{
                color: $white;
            }
        }
        .Mui-checked{
            color: $primaryColorCompany;
        }
        .Mui-selected{
            color: $white;
            background-color: $primaryColorCompany;
            &:hover{
                background-color: $primaryColor;
            }
        }
    }
    .footer-container{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: $short-space 0;
        border-bottom: none;
        @media only screen and (max-width: $breakpoint-1125){
            justify-content: space-around;
        }
        .rows-number-container{
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            margin: $medium-space;
            .rows-number-text{
                @extend .light-serif;
                color: $mediumGray;
                padding: 0 $short-space;
            }
        }
        .rows-selected-container{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: $medium-space;
            .rows-selected-number{
                @extend .bold-serif;
                color: $black;
                padding: 0 $short-space;
            }
            .rows-selected-text{
                @extend .regular-serif;
                color: $mediumGray;
                padding: 0 $short-space;
            }
        }
        .pagination-container{
            display: flex;
            margin: $medium-space;
            border-radius: $short-space;
            @media only screen and (max-width: $breakpoint-1125){
                background-color: $lightGray;
                padding: $short-space;
            }
        }
    }
    .not-result-list{
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        .not-results-text{
            @extend .bold-serif;
            margin: $medium-space;
        }
    }
}

.MuiPagination-root {
    overflow-x: hidden;
    @media only screen and (max-width: $breakpoint-540){
        overflow-x: scroll;
    }
    .MuiPagination-ul {
        flex-wrap: nowrap;
        li {
            &:first-child {
                flex-basis: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                button{
                    svg{
                        display: none;
                    }
                    &::after {
                        margin-left: 10px;
                        content: 'Primero';
                    }
                }
            }
            &:nth-child(2){
                flex-basis: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                button{
                    svg{
                        display: none;
                    }
                    &::after {
                        margin-left: 10px;
                        content: 'Anterior';
                    }
                }
            }
            &:last-child {
                flex-basis: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                button{
                    svg{
                        display: none;
                    }
                    &::before {
                        margin-right: 10px;
                        content: 'Último';
                    }
                }
            }
            &:nth-last-child(2){
                flex-basis: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                button{
                    svg{
                        display: none;
                    }
                    &::before {
                        margin-right: 10px;
                        content: 'Siguiente';
                    }
                }
            }
        }
    }
}