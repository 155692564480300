@import '../../index.scss';

.state-filters-profile-container {
    max-width: $max-breakpoint-limit !important;
    margin: 0 auto !important;

    @media only screen and (max-width: $breakpoint-1125) {
        max-width: 90% !important;
    }

    .container-profile-buttons {
        justify-content: flex-start;

        @media only screen and (max-width: $breakpoint-540) {
            justify-content: center;
            margin: 0px;
        }

        .button-profile-option{
            background-color: $secondaryColor;
            border-radius: 30px;
            border: 1px solid $white;
            color: $white;
            margin: $short-space $big-space;
            padding: $short-space $short-space-secction;

            @media only screen and (max-width: $breakpoint-540) {
                width: 350px;
            }
        }

        .button-profile-option-active {
            border-radius: 30px;
            border: 1px solid $white;
            background-color: $white;
            color: $primaryColor;
            margin: $short-space $big-space;
            padding: $short-space $small-space-secction;

            @media only screen and (max-width: $breakpoint-540) {
                width: 350px;
            }
        }

    }
}
.additional-info-profile-container{
    padding: $small-space 0px;
}

.additional-info-card-container{
    margin: $small-space  0px!important;
    background-color: $grayRecaptcha;
    border: 1px solid $softGray;
    border-radius: $short-space;
    width: 100%;
    padding: $small-space $short-space-secction;

     .additional-info-card-body-content {
        .title-section-text {
            padding: $short-space ;
            align-items: center;
            .description-text{
                color: #404040;
            }
        }
        .location-text{
            color: $darkGray;
            font-family: $Ancizar-bold-italic-serif;
        }
     }
}