@import '../../../index.scss';


.setting-container {
  max-width: $max-breakpoint-limit !important;
  margin: $small-space-secction auto !important;

  @media only screen and (max-width: $breakpoint-960) {
    max-width: 90% !important;
  }
}

.tab-description {
  @extend .regular-serif;
  color: $primaryColor !important;
  margin: $medium-space 0 !important;
}

.surveyUrlIcon{
  background-color: $primaryColor !important;
}

.bold-serif-secondTitle{
  @extend .bold-serif;
  color: $mediumGray;
}
.state-option-section {
  background-color: $secondaryColor;
  margin-top: 0 !important;
  justify-content: flex-start;
}

.state-option-container {
  max-width: $max-breakpoint-limit !important;
  margin: 0 auto !important;

  @media only screen and (max-width: $breakpoint-960) {
    max-width: 90% !important;
  }

  .container-buttons {
    justify-content: space-around;

    .link-state-bar {
      text-decoration: none;
      margin: $short-space $big-space;
      padding: $short-space;

      .button-definition {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        background-color: $secondaryColor;
        border-radius: 30px;
        border: 1px solid $white;
        color: $white;
        padding: $short-space;
        height: 35px;
        width: 240px;

        h5 {
          text-align: center;
        }
      }

      .button-definition-active {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        background-color: $white;
        border-radius: 30px;
        border: 1px solid $white;
        color: $primaryColor;
        padding: $short-space;
        height: 35px;
        width: 240px;

        h5 {
          text-align: center;
        }
      }
    }
  }
}


.setting-form{
  padding-right: 10px;
  @media only screen and (max-width: $breakpoint-960) {
    padding-right: 0;
  }

  .setting-container2 {
    padding: $medium-space $small-space-secction;
    border: 1px solid $primaryColor;
    border-radius: $short-space;
    margin-bottom: $medium-space;


    .surveys-content2 {

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .field-box-text {
          @extend .regular-serif;
          color: $primaryColor;
          margin-bottom: 10px;
        }
      }

      .form-button-container{
        
        justify-content: flex-start;
        margin-bottom: 10px;
        .form-button{
          background-color: $secondaryColor;
          @extend .regular-serif;
          font-size: $font-size-h5;
          color: $white;
        }
      }

      .upload-button-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        margin-top: $short-space;
        .upload-button {
            width: 200px;
            background-color: $secondaryColor;
            color: $white;
            padding: $small-space 0;
            @media only screen and (max-width: $breakpoint-540) {
                width: 150px;
            }
            input {
                display: none;
            }
            &:hover {
                background-color: $primaryColor!important;
            }
        }

        .MuiButton-contained.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }    

  .setting-title {
    color: $primaryColor;
    @extend .regular-serif;
    margin-bottom: 10px;
  }   

}




.buttons-setting-survey {
  margin-top: $short-space-secction;
  padding: 10px 10px 10px 0;
  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
    display: flex;
    flex-flow: inherit;
  }

  .left-buttons{
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .cancel-button {
      width: 170px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }
  }

  .right-buttons {
    justify-content: flex-end;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .save-button {
      width: 170px;
      height: 32px;
      background-color: $secondaryColor;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;
      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
      &:disabled {
        background-color: $softGray !important;
      }
    }
  }

  .no-button {
    display: none;
  }

}