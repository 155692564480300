@import '../../../index.scss';

.select-actions-container{
    margin: $medium-space auto !important;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: $short-space;
    border: 1px solid $primaryColor;
    padding: $medium-space;
    .select-actions-title{
        margin: $short-space;
        margin-right: 15px;
        @media only screen and (max-width: $breakpoint-720){
            margin: $short-space 0;
        }
    }
    .select-actions-control{
        margin: $short-space;
        margin-right: 15px;
        @media only screen and (max-width: $breakpoint-720){
            margin: $short-space 0;
        }
    }
    .select-actions-button{
        background-color: $primaryColorCompany;
        color: $white;
        margin: $short-space 0;
        width: 150px;
        @media only screen and (max-width: $breakpoint-720){
            margin-right: 0;
        }
        &:hover{
            background-color: $primaryColor;
        }
    }
}