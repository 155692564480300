@import '../../index.scss';

.container-graduate-buttons {
  justify-content: space-around;
  @media only screen and (max-width: $breakpoint-540) {
    justify-content: center;
    margin: 0px;
  }

  .button-graduate-definition {
    background-color: $secondaryColor;
    border-radius: 30px;
    border: 1px solid $white;
    color: $white;
    margin: $short-space $big-space;
    padding: $short-space;
    @media only screen and (max-width: $breakpoint-540) {
      width: 350px;
    }
  }

  .button-graduate-definition-active {
    border-radius: 30px;
    border: 1px solid $white;
    background-color: $white;
    color: $primaryColor;
    margin: $short-space $big-space;
    padding: $short-space;
  }

  .button-graduate-number-active {
    @extend .regular-serif;
    margin: 0 $short-space;
    border-radius: 30px;
    border: 1px solid $white;
    background-color: $primaryColor;
    color: $white;
    padding: $short-space;
  }
}

//offers state tabs
.button-number-active {
  @extend .regular-serif;
  margin-left: 5px;
  border-radius: 30px;
  border: 1px solid $white;
  background-color: $primaryColor !important;
  color: $white;
  padding: $short-space;
}

//offers list
.double-actions{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: $small-space 0;
  .firts-actions{
    width: 50%;
    @media only screen and (max-width: $breakpoint-540) {
      width: 100%;
    }
  }
  .second-actions{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: $breakpoint-540) {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

.simple-right-actions{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding: $small-space 0;
}
.finalized-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: $small-space 0;
  @media only screen and (max-width: $breakpoint-800) {
    flex-flow: column wrap;
  }
}
.simple-right-actions2 {
  display: flex;
  // flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding: $small-space 0;
  @media only screen and (max-width: $breakpoint-800) {
    justify-content: center;
  }
}
.vacancy-box{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: $small-space;
  margin-bottom: $medium-space !important;
  border: 1px solid $softGray;
  border-radius: $short-space;
  color: $primaryColor;
}
.vacancy-box2{
  display: flex;
  // flex-flow: row wrap;
  justify-content: flex-start;
  @media only screen and (max-width: $breakpoint-800) {
    justify-content: center;
  }
}

.delete-offer-btn{
  @extend .regular-serif;
  border: 1px solid $primaryError !important;
  color: $primaryError !important;
  margin: $short-space !important;
  height: 30px;
  width: 100px;
}
.modify-offer-btn{
  @extend .regular-serif;
  border: 1px solid $orangeColor !important;
  color: $browColor !important;
  margin: $short-space !important;
  height: 30px;
  width: 100px;
}
.see-offer-btn{
  @extend .regular-serif;
  background-color: $secondaryColor !important;
  color: $white !important;
  height: 30px;
  width: 100px;
  margin: $short-space !important;
  &:hover{
    background-color: $primaryColor !important;
  }
}
.applicants-btn{
  @extend .regular-serif;
  background-color: $secondaryColor !important;
  color: $white !important;
  height: 30px;
  margin: $short-space !important;
  &:hover{
    background-color: $primaryColor !important;
  }
}
.change-offer-btn{
  @extend .regular-serif;
  background-color: $primaryColorGraduate !important;
  color: $white !important;
  height: auto;
  margin: $short-space !important;
  &:hover{
    background-color: $primaryColorGraduateLight !important;
  }
  &:disabled {
    background-color: $softGray !important;
    color: $mediumGray !important;
  }
}
.reuse-offer-btn{
  @extend .regular-serif;
  border: 1px solid $primaryColorGraduate !important;
  color: $browColor !important;
  margin: $short-space !important;
  height: 30px;
  width: 100px;
  &:disabled {
    background-color: $softGray !important;
    color: $mediumGray !important;
  }
}
.alert-simple-title{
  font-size: $font-size-h6;
  @extend .regular-serif;
  color: $primaryColor;
}
.alert-simple-text{
  font-size: $font-size-h6;
  @extend .regular-serif;
  color: $primaryColor;
}
.alert-bol-title{
  font-size: $font-size-h5;
  @extend .bold-serif;
  color: $primaryColor;
}

.big-warning-icon{
  color: $primaryError;
  font-size: 50px !important;
}

.big-info-icon{
  color: $primaryColor;
  font-size: 50px !important;
}

.big-cancel-icon{
  color: $warningColor;
  font-size: 50px !important;
}