@import '../../../index.scss';

.announcement-filters-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin: $medium-space 0 !important;
  border: 1px solid $primaryColor;
  border-radius: $short-space;
  padding: 0;
  box-shadow: 2px 2px 6px $mediumGray !important;

  .announcement-filters-button {
    background-color: $white;
    color: $primaryColor;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    .announcement-button-icon {
      display: flex;
      justify-content: center;
    }
  }

  .announcement-filters-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: $big-space $small-space-secction;
    background-color: $white;

    .announcement-filters-form {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: $medium-space;
      height: auto;
      overflow: hidden;

      .form-icon {
        color: $primaryColor;
      }

      .field-box-margin {
        margin: $small-space 0 !important;
        padding: 0 $small-space;
        .field-box-title {
          @extend .bold-serif;
          color: $secondaryColor;
        }
      }
    }
  }
}