@import '../../../index.scss';

.event-news-form{
  padding-right: 10px;
  .event-form-container1{
    margin: 0 !important;
    border: 1px solid $primaryColor;
    border-radius: $short-space;
    .title-new-event-container {
      background-color: $primaryColor;
      .title{
        @extend .bold-serif;;
        color: $white;
        padding: $medium-space $small-space-secction;
        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }
    .event-form-content1{
      padding: $medium-space $small-space-secction;
      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
        .field-box-title {
          color: $primaryColorCompany;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
      }

      .checkbox-container {
        .checkbox {
          margin: 0 !important;
          padding: 0;

          .MuiFormControlLabel-label {
            color: $primaryColor;
            @extend .regular-serif;
            font-size: $font-size-h5;
          }

          .Mui-checked {
            color: $secondaryColor;
          }
        }
      }
    }
  }

  .event-form-container2 {
    margin: $medium-space 0 !important;
    border: 1px solid $primaryColor;
    border-radius: $short-space;
  .title-new-event-container {
      background-color: $primaryColor;

      .title {
        @extend .bold-serif;
        color: $white;
        padding: $medium-space $small-space-secction;

        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }

    .event-form-content2 {
      padding: $medium-space $small-space-secction;

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColorCompany;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
        .field-box-text{
          @extend .regular-serif;
          color: $primaryColor;
        }
      }

      .checkbox-container {
        .checkbox {
          margin: 0 !important;
          padding: 0;
          .MuiFormControlLabel-label {
            color: $primaryColor;
             @extend .regular-serif;
            font-size: $font-size-h5;
          }
          .Mui-checked {
            color: $secondaryColor;
          }
        }
      }
      .checkbox-container-description{
        margin-left: 40px;
        @extend .regular-serif;
      }
      .upload-button-container{
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        .upload-button{
          background-color: $secondaryColor;
          color: $white;
          input{
            display: none;
          }
          &:disabled{
            background-color: $lightGray !important;
            color: $mediumGray !important;
          }
        }
        .MuiButton-contained.Mui-disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            box-shadow: none;
            background-color: rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
}

.buttons-container-news {
  margin-top: $short-space-secction !important;
  padding-right: 10px;
  .right{
    justify-content: flex-end;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .confirm-button {
      width: 120px;
      background-color: $secondaryColor;
      color: $white;
      height: 32px;
      margin-top: $small-space;
      @media only screen and (max-width: $breakpoint-720) {
        width: 160px;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
      &:hover{
        background-color: $primaryColor;
      }
    }

    .preview-button{
      width: 120px;
      background-color: $white;
      margin-right: $medium-space;
      height: 32px;
      margin-top: $small-space;
      color: $primaryColor;
      border: 1px solid $primaryColor;
      @media only screen and (max-width: $breakpoint-720) {
        margin-right: 0;
        width: 160px;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
      &:hover{
        background-color: $greenSuccess;
      }
    }
  }
  .left{
    justify-content: flex-start;
    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }
    .no-button{
      display: none;
    }
    .cancel-button {
      width: 120px;
      background-color: $white;
      margin-right: $medium-space;
      height: 32px;
      margin-top: $small-space;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;
      @media only screen and (max-width: $breakpoint-720) {
        margin-right: 0;
        width: 160px;
      }
    }
    .delete-button {
      background-color: $white;
      margin-top: $small-space;
      height: 32px;
      color: $primaryError;
      border: 1px solid $primaryError;
      @media only screen and (max-width: $breakpoint-720) {
        width: 160px;
      }
    }
  }
}

.card-image-container{
  height: 200px;
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 6px $mediumGray !important;
  margin: $small-space 0 !important;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  .card-header-container{
    border-bottom: 1px solid $mediumGray;
    padding: $short-space;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    .card-title-item{
        color: $darkGray;
        text-overflow: ellipsis;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
    }
  }
  .card-image-content{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .image-noticia{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.richtext-box{
  border: 1px solid $softGray;
  min-height: 200px;
}