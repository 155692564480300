@import '../../../index.scss';

.offer-dialog-container {
    .offer-dialog-header {
        margin: 0;
        padding-bottom: 0;
    }
    .offer-icon-content{
        opacity: 1;
        text-align: center;
        margin-bottom: -$medium-space;
    }

    .offer-dialog-content {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 8px 50px !important;
        margin-top: 0px;
        margin-bottom: $medium-space;
        display: flex;
        justify-content: center;
        flex-flow: column;
        .text-content-header{
            color: $black;
            line-height: 1.5 !important;
            @media only screen and (max-width: $breakpoint-540) {
                font-size: $font-size-h7;
            }
        }
        .text-content-dialog {
            color: $black;
            line-height: 1.5 !important;
            @media only screen and (max-width: $breakpoint-540) {
                font-size: $font-size-h7;
            }
        }

    }
    .line{
        border-top: 2px solid $softGray;
        opacity: 1;
        margin: 0px 10px;
    }

    .offer-dialog-actions {
        padding: 14px 40px !important;
        justify-content: space-between;
        display: flex;
        flex-flow: row nowrap;

        @media only screen and (max-width: $breakpoint-540) {
            flex-flow: column nowrap;
            justify-content: center;
        }

        .back-button {
            background-color: transparent;
            border: 1px solid $primaryColorGraduate;
            color: $primaryColorGraduate;
            width: 145px;
            @media only screen and (max-width: $breakpoint-540) {
                margin-bottom: 10px;
            }
        }

        .continue-button {
            color: $white;
            width: 145px;
            @media only screen and (max-width: $breakpoint-540) {
                margin-top: 10px;
                margin-left: 0;
            }
            &:disabled {
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }
    }
}