@import '../../../index.scss';

.state-bar-container {
  max-width: $max-breakpoint-limit !important;
  margin: 0 auto !important;

  @media only screen and (max-width: $breakpoint-1125) {
    max-width: 90% !important;
  }

  .container-buttons {
    justify-content: space-around;

    .link-state-bar {
      text-decoration: none;
      margin: $short-space $big-space;
      padding: $short-space;

      .button-definition {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        background-color: $secondaryColor;
        border-radius: 30px;
        border: 1px solid $white;
        color: $white;
        padding: $short-space;
        height: 35px;
        width: 160px;
        h5{
          text-align: center;
        }
      }

      .button-definition-active {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        background-color: $white;
        border-radius: 30px;
        border: 1px solid $white;
        color: $primaryColor;
        padding: $short-space;
        height: 35px;
        width: 160px;
        h5{
          text-align: center;
        }
      }
    }
  }
}