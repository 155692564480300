@import '../../index.scss';

.headquarter-register-dialog-container {
  .headquarter-register-dialog-header {
    margin: 0;
    padding-bottom: 0;
  }

  .headquarter-register-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;

    .field-box-margin {
      margin-top: $small-space !important;

      .field-box-title {
        @extend .bold-serif;
        color: $primaryColor;
        margin: 10px 0;
      }

    }

  }

  .headquarter-register-dialog-actions {
    padding: 14px 50px !important;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;

    @media only screen and (max-width: $breakpoint-540) {
      flex-flow: column nowrap;
      justify-content: center;
    }

    .cancel-button {
      background-color: $white;
      border: 1px solid $primaryColorGraduate;
      color: $primaryColorGraduate;
      width: 116px;
      @media only screen and (max-width: $breakpoint-540) {
        margin-bottom: 10px;
        font-size: $font-size-h7;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
    }

    .add-button {
      background-color: $primaryColorGraduate;
      color: $white;
      width: 116px;
      @media only screen and (max-width: $breakpoint-540) {
        margin-top: 10px;
        margin-left: 0;
        font-size: $font-size-h7;
      }
      &:hover {
        background-color: $primaryColorGraduateLight !important;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
    }

  }
}