@import '../../../index.scss';

.job-offers-search-container{
    background-color: $primaryColor;
    padding: $small-space-secction $big-space;
    color: $white;
    border-radius: $short-space;
    .offers-search-title-box{
        color: $white;
        margin-bottom: $small-space;
    }
    .offers-search-boxes{
        justify-content: space-between;
        @media only screen and (max-width: $breakpoint-720){
            justify-content: center;
        }
    }
    .btn-container{
        display: flex;
        justify-content: flex-end;
        margin-top: $big-space;
        @media only screen and (max-width: $breakpoint-720){
            justify-content: center;
        }
        .search-btn{
            display: flex;
            justify-content: space-between;
            background-color: $primaryColorCompany;
            width: 120px;
            color: $white;
        }
    }
}