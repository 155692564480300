@import '../../index.scss';

.result-container {
  background-color: $white !important;
  box-shadow: 2px 2px 6px $mediumGray !important;
  padding: 24px;

  .summary-title {
    @extend .light-serif;
    color: $primaryColorGraduate;
    @media only screen and (max-width: $breakpoint-540) {
      font-size: $font-size-h2;
      text-align: center;
    }
  }
  .summary-description {
    @extend .light-serif;
    color: $primaryColor;
    margin: $medium-space 0 $medium-space;
  }
  .result-description {
    @extend .bold-serif;
    color: $primaryColor;
    margin: $medium-space 0 $medium-space;
  }
  .second-content{
    padding: 24px;
    border: 1px solid $softGray;
    border-radius: 5px;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-960) {
      justify-content: center;
    }
    .dont-break-out {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
    }
    .btn-result{
      color: $white;
      margin: $small-space-secction;
      width: 200px;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $breakpoint-960) {
        margin: $short-space;
      }
      .number-state{
        padding-right: 20px;
        @media only screen and (max-width: $breakpoint-320) {
          padding-right: 5px;
        }
      }
      .btn-text{
        @media only screen and (max-width: $breakpoint-540) {
          font-size: $font-size-h7;
        }
      }
      .btn-icon{
        padding-left: 10px;
      }
    }
  }
  .third-content {
    padding: 24px 0;
    justify-content: flex-end;
    .home-btn{
      color: $white;
      background-color: $primaryColorGraduate;
      text-transform: uppercase;
    }
  }
}