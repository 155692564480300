@import '../../../index.scss';

.more-filters-box{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin: $medium-space 0 !important;
    border: 1px solid $secondaryColor;
    border-radius: $short-space;
    padding: 0;
    box-shadow: 2px 2px 6px $mediumGray !important;
    .more-filters-button{
        background-color: $secondaryColor;
        color: $white;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        &:hover{
            background-color: $primaryColor;
        }
        .more-button-icon{
            display: flex;
            justify-content: center;
        }
    }
    .more-filters-container{
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: $big-space $small-space-secction;
        .more-filters-form{
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: $medium-space;
            height: auto;
            overflow: hidden;
            @media only screen and (max-width: $breakpoint-960){
                max-height: 400px;
                overflow-y: scroll;
                overflow-x: hidden;
            }
            .more-filters-form-title{
                @extend .bold-serif;
                color: $primaryColor;
                padding: 0 $small-space;
            }
            .field-box-margin{
                margin: $small-space  0 !important;
                padding: 0 $small-space;
                .field-box-title{
                    @extend .bold-serif;
                    color: $secondaryColor;
                }
                .MuiPickersDateRangePickerInput-root{
                    width: 100%;
                }

                .checkbox{
                    
                    .MuiFormLabel-root{
                        color: $secondaryColor;
                        @extend .bold-serif;
                        font-size: $font-size-h4;
                    }
                    .MuiTypography-body1 {
                        color: $darkGray;
                        @extend .bold-serif;
                    }
                
                    
                }
            }
        }
    }
}