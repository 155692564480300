@import '../../../index.scss';

.notification-box{
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: $small-space;
    top:0px;
    box-shadow: 2px 2px 6px $mediumGray !important;
}