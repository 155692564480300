@import '../../../index.scss';

.main-report-container {
  margin: $medium-space 0 !important;

  .title-container-report {
    background-color: $secondaryColor;

    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;

      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }

  .report-container {
    margin-bottom: 10px;
    border: 1px solid $secondaryColor;
    border-radius: $short-space;

    .report-content {
      padding: $medium-space $small-space-secction;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .checkbox {
          margin-left: 0 !important;
          padding-left: 0 !important;
          .MuiFormControlLabel-label {
            color: $primaryColor;
            font-family: $Ancizar-bold-serif;
            font-size: $font-size-h5;
          }

          .Mui-checked {
            color: $secondaryColor;
          }
        }
      }
    }
  }
}