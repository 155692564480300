@import '../../../index.scss';


.option-answer-container {
  display: flex;
  flex-flow: nowrap !important;
  justify-content: flex-end !important;

  .option-btn {
    background-color: $white;
    color: $secondaryColor;
    justify-content: space-between;
    display: flex;


    &:disabled {
      background-color: $lightGray !important;
      color: $mediumGray !important;
    }
  }

  .option-position {
    justify-content: flex-start;
  }  
}

.border_icon {
  border: 2px solid !important;
  border-color: $primaryColor !important;
  color: $primaryColor !important;
}