@import '../../../index.scss';

.landing-company-container {
  margin: 0;
  padding: 0;
  min-width: 0;
  flex-flow: row wrap;
  background-color: $primaryColorCompany;
  color: $white;
  @media only screen and (max-width: $breakpoint-960) {
    height: auto;
  }

  .first-part {
    @media only screen and (max-width: $breakpoint-960) {
      justify-content: center;
      align-items: center;
    }
    .company-img {
      width: 170px;
      height: 200px;
      border-radius: 0 30px 0 30px;
      margin: 42px 0 0 42px;
      @media only screen and (max-width: $breakpoint-960) {
        margin: 0;
        margin-top: $medium-space;
      }
    }

    .text-container {
      @extend .regular-serif;
      @media only screen and (max-width: $breakpoint-960) {
        justify-content: center;
        align-items: center;
      }
      .title-container {
        margin-left: $medium-space;
        letter-spacing: 0px;
        margin-top: 125px;
        @media only screen and (max-width: $breakpoint-960) {
          font-size: $font-size-h2 !important;
          margin: 20px 100px 20px 100px;
          text-align: center;
        }
      }
      .register-text {
        margin-top: $short-space-secction;
        margin-left: $medium-space;
        line-height: $small-space-secction;
      }
    }
  }

  .container-btn {
    align-items: flex-start;
    margin-top: 25px;
    margin-left: 222px;
    margin-bottom: $large-space-secction;
     @media only screen and (max-width: $breakpoint-960) {
       justify-content: center;
       align-items: center;
       margin-left: 0;
     }
    .register-btn {
      font-size: $font-size-h7;
      @extend .regular-serif;
      background-color: $white;
      color: $primaryColorCompany !important;
      &:hover {
        background-color: $primaryColorGraduateLight !important;
        color: $white !important;
      }
    }
    .consult-btn {
      background-color: $white;
      color: $primaryColorCompany !important;
      margin-top: 20px;
      &:hover {
        background-color: $primaryColorGraduateLight !important;
        color: $white !important;
      }
    }
  }

  .general-box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: $short-space-secction;
  }

  .column-link {
    @extend .bold-serif;
    border-left: solid 15px $white;
    border-radius: 10px 0 0 0;
    color: $white;
    padding-left: $small-space-secction;
    margin: 10px 0 10px 0;
    text-decoration: none;
    @media only screen and (max-width: $breakpoint-960) {
      font-size: $font-size-h2 !important;
    }
  }


}
