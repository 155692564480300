@import '../../../../index.scss';

.column-states{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.active-state{
    background-color: $primaryColorGraduate;
}
.signed-up-state{
    background-color: $primaryColor;
}
.verified-state{
    background-color: $primaryColorCompany;
}
.inactive-state{
    background-color: $darkGray;
}
.null-state{
    background-color: $warningColor;
}
.rejected-state{
    background-color: $primaryError;
}
.state-button-cell{
    font-size: $font-size-h6;
    @extend .regular-serif;
    color: $white;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    padding: $short-space;
}

.state-applicant-button-cell{
    font-size: $font-size-h4;
    @extend .regular-serif;
    color: $white;
    width: 170px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    padding: $short-space;
}

.name-cell, .surname-cell{
    font-size: $font-size-h5;
    @extend .bold-serif;
    color: $black;
    
}
.doc-cell, .date-cell, .curriculum-cell{
    color: $darkGray;
    font-size: $font-size-h5;
    @extend .regular-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.description-cell{
    font-size: $font-size-h5;
    @extend .regular-serif;
    color: $darkGray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.column-header-title{
    font-size: $font-size-h5;
    @extend .regular-serif;
    color: $white;
    margin: $short-space 0;
    font-weight: lighter;
}
.column-header-break{
    font-size: $font-size-h5;
    @extend .light-serif;
    color: $white;
    line-height: normal;
    width: 100px;
    margin: $short-space 0;
    font-weight: lighter;
}
.color-arrows{
    color: $white;
}

.tab-description{
    @extend .regular-serif;
    color: $primaryColor !important;
    margin: $medium-space 0 !important;
}

.header-table-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .search-container-outer{
        width: 50%;
        @media only screen and (max-width: $breakpoint-960){
            width: 100%;
        }
    }
    .results-found-container{
        text-align: right;
        margin: $short-space 0;
        color: $mediumGray;
        @media only screen and (max-width: $breakpoint-960){
            width: 50%;
            text-align: start;
        }
        @media only screen and (max-width: $breakpoint-540){
            width: 100%;
        }
    }
    .select-all-btns{
        @media only screen and (max-width: $breakpoint-960){
            width: 50%;
            text-align: end;
        }
        @media only screen and (max-width: $breakpoint-540){
            width: 100%;
            text-align: start;
        }
        .select-all-check{
            color: $primaryColorCompany;
            border-color: $primaryColorCompany;
            margin: $short-space 0;
            width: 200px;
            @media only screen and (max-width: $breakpoint-540){
                margin-right: $medium-space;
            }
            &:hover{
                background-color: $primaryColorCompany;
                color: $white;
            }
        }
        .select-any-check{
            color: $primaryColorCompany;
            border-color: $primaryColorCompany;
            margin: $short-space $medium-space;
            width: 200px;
            @media only screen and (max-width: $breakpoint-540){
                margin: $short-space 0;
            }
        }
    }
}