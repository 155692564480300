@import '../../../../index.scss';

.search-ldap-view-container{
    .title-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: $medium-space 0px;
        .security-icon {
          font-size: $icon-size-small;
          margin-right: $medium-space;
          color: $primaryColor;
        }
    }


}