@import '../../../index.scss';

.news-events-search-container {
    background-color: $primaryColor;
    padding: $short-space-secction $small-space-secction;
    color: $white;
    border-radius: $short-space;

    .news-events-search-title {
        @extend .light-serif;
        margin-top: $short-space;
        margin-bottom: $medium-space;
    }

    .news-events-search-boxes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: $breakpoint-720) {
            justify-content: center;
        }
        .news-events-boxes{
            display: flex;
            justify-content: space-between;
            padding: 5px 0px;

        }
        .special-column-right {
            padding-right: 8px;

            @media only screen and (max-width: $breakpoint-720) {
                padding: 0;
                margin-top: $medium-space;
                margin-bottom: $medium-space;
            }
        }

        .special-column-left {
            padding-left: 8px;

            @media only screen and (max-width: $breakpoint-720) {
                padding: 0;
                margin-top: $medium-space;
                margin-bottom: $medium-space;
            }
        }
        .filter-buttons-offers {
            display: flex;
            justify-content: flex-end;
            @media only screen and (max-width: $breakpoint-720) {
                justify-content: center;
            }

            .search-button{
                display: flex;
                justify-content: space-between;
                color: $white;
                background-color: $secondaryColor;
                margin: $short-space;
                width: 100px;
                &:hover{
                  background-color: $primaryColorCompany;
                }
                &:disabled {
                    background-color: $softGray !important;
                    color: $mediumGray !important;
                }
                @media only screen and (max-width: $breakpoint-540) {
                  width: 120px;
                }
            }
            .clear-filter-button{
                color: $white;
                background-color: $warningColor;
                margin: $short-space;
                width: 120px;
                &:hover{
                  background-color: $orangeColor;
                }
                &:disabled {
                    background-color: $softGray !important;
                    color: $mediumGray !important;
                }
            }
        }
    }

}