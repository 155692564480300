@import '../../../index.scss';

.trainingevent-form {
  padding-right: 10px;

  @media only screen and (max-width: $breakpoint-960) {
    padding-right: 0;
  }

  .trainingevent-container {

    border: 1px solid $primaryColor;
    border-radius: $short-space;
    margin-bottom: $medium-space;

    .title-container {
      background-color: $primaryColor;
      margin: 0 !important;

      .title {
        @extend .bold-serif;
        color: $white;
        padding: $medium-space $small-space-secction;

        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }

    .trainingevent-content {
      padding: $medium-space $small-space-secction;

      .upload-button-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;

        .upload-button {
          background-color: $secondaryColor;
          color: $white;

          input {
            display: none;
          }

          &:disabled {
            background-color: $lightGray !important;
            color: $mediumGray !important;
          }
        }

        .MuiButton-contained.Mui-disabled {
          color: rgba(0, 0, 0, 0.26) !important;
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.12) !important;
        }
      }

      .line {
        border-bottom: 1px solid $softGray;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title {
          color: $primaryColor;
          @extend .bold-serif;
          margin-bottom: 10px;
        }

        .field-box-text {
          @extend .regular-serif;
          color: $primaryColor;
          margin-bottom: 10px;
        }
      }

      .form-button-container {

        justify-content: flex-start;
        margin-bottom: 10px;

        .form-button {
          background-color: $secondaryColor;
          @extend .regular-serif;
          font-size: $font-size-h5;
          color: $white;
        }
      }

      .card-image-container {
        height: 150px;
        width: 100%;
        background-color: $white;
        box-shadow: 2px 2px 6px $mediumGray !important;
        margin: $small-space 0 !important;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;

        .card-header-container {
          border-bottom: 1px solid $mediumGray;
          padding: $short-space;
          height: auto;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;

          .card-title-item {
            color: $darkGray;
            text-overflow: ellipsis;
            height: 20px;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
          }
        }

        .card-image-content {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          .image-noticia {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .curriculum-section-accordion-container {
        margin-bottom: $big-space !important;
        border: 1px solid $secondaryColor;

        .accordion-summary {
          color: $white;
          background-color: $secondaryColor;

          .MuiSvgIcon-root {
            color: $white;
            font-size: 26px;
          }

          .summary-title {
            font-family: $Ancizar-bold-serif;
            font-size: 26px;

            @media only screen and (max-width: $breakpoint-540) {
              font-size: 20px;

            }
          }
        }
      }

      .curriculum-information-accordion-details {
        overflow-y: auto;
        overflow-x: hidden;
        padding: $small-space $small-space-secction !important;
      }
    }


  }
}

.buttons-container-trainingevent {
  margin-top: $short-space-secction;
  padding: 10px 10px 10px 0;

  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .left-buttons {
    display: flex;
    justify-content: flex-start;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }

    .cancel-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryColorGraduate;
      border: 1px solid $primaryColorGraduate;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }

    .delete-button {
      width: 150px;
      height: 32px;
      background-color: $white;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $primaryError;
      border: 1px solid $primaryError;
      margin: 0 10px;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }
    }
  }

  .right-buttons {
    justify-content: flex-end;

    @media only screen and (max-width: $breakpoint-720) {
      justify-content: space-around;
    }

    .save-button {
      width: 150px;
      height: 32px;
      background-color: $secondaryColor;
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;

      @media only screen and (max-width: $breakpoint-720) {
        margin: 10px;
      }

      &:disabled {
        background-color: $softGray !important;
      }
    }
  }

  .no-button {
    display: none;
  }

}