@import '../../../../index.scss';

.title-container-result {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $primaryColor;
    margin-top: $small-space-secction !important;
    margin-bottom: $short-space !important;
    .seach-page-icon {
        font-size: $icon-size-small;
        margin-right: $small-space;
    }
    .line-result {
        border-bottom: 1px solid $softGray;
    }
}

.img-fluid{
    width: 100%;
}