@import '../../index.scss';

.carrusel-container{
  justify-content: center;
  margin: $small-space-secction !important;
  .carrusel-content{
    max-width: 800px;
    flex-grow: 1;
    .category-news{
      @extend .bold-serif;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: $short-space;
      color: $white;
      background-color: $secondaryColor;
      padding-right: 5px;
      .category-icon {
        font-size: $icon-size-small;
        margin: 0 $short-space;
      }
    }
    .category-events {
      @extend .bold-serif;
      color: $darkBrown;
      background-color: $warningColor;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: $short-space;
      padding-right: 5px;

      .category-icon {
        font-size: $icon-size-small;
        margin: 0 $short-space;
      }
    }
    .img-container{
      width: 95%;
      margin: 0 auto;
    }
    .event-time-title{
      @extend .light-serif;
      color: $primaryColor;
      background-color: $white;
      margin-top: 20px;
    }
    .date-news{
      @extend .regular-serif;
      color: $secondaryColor;
      background-color: $white;
      margin-left: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .date-icon {
        font-size: $icon-size-small;
      }
    }
    .date-events {
      @extend .regular-serif;
      color: $primaryColor;
      background-color: $white;
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .date-icon {
        font-size: $icon-size-small;
      }
    }
    .news-title{
      @extend .bold-serif;
      color: $primaryColor;
      margin-bottom: 15px;
      margin-top: 10px;
      margin-right: $small-space-secction;
      @media only screen and (max-width: $breakpoint-960) {
        font-size: $font-size-h2;
      }
    }
    .news-summary {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      @extend .regular-serif;
      color: $primaryColor;
      margin-bottom: 15px;
      @media only screen and (max-width: $breakpoint-960) {
        font-size: $font-size-h5;

      }
    }
    .MuiMobileStepper-dotActive{
      background-color: $secondaryColor;
    }

    .button{
      @extend .regular-serif;
      font-size: $font-size-h5;
      color: $white;
      background-color: $secondaryColor;
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
      .MuiSvgIcon-root{
        margin: 0 !important;
      }
    }
  }
}
.container-button-left {
  padding: 14px 50px !important;
  justify-content: flex-start;
  display: flex;
  flex-flow: row nowrap;

  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center !important;
  }

  .left-button {
    @extend .bold-serif;
    font-size: $font-size-h5;
    background-color: $secondaryColor;
    color: $white;
    height: 40px;
    @media only screen and (max-width: $breakpoint-720) {
      width: 200px;
      font-size: $font-size-h6;
    }
    &:hover {
      background-color: $primaryColor !important;
    }

    &:disabled {
      background-color: $softGray !important;
      color: $mediumGray !important;
    }
  }

  .no-button {
    display: none;
  }
}

.container-button-right {
  padding: 14px 50px !important;
  justify-content: flex-end;
  display: flex;
  flex-flow: row nowrap;

  @media only screen and (max-width: $breakpoint-720) {
    justify-content: center;
  }

  .right-button {
    @extend .bold-serif;
    font-size: $font-size-h6;
    background-color: $secondaryColor;
    color: $white;
    height: 40px;
    @media only screen and (max-width: $breakpoint-720) {
      width: 200px;
      font-size: $font-size-h7;
    }

    &:hover {
      background-color: $primaryColor !important;
    }

    &:disabled {
      background-color: $softGray !important;
      color: $mediumGray !important;
    }
  }

  .no-button {
    display: none;
  }
}