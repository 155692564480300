@import '../GraduateCurriculum.scss';

.academic-information-dialog-container{
    .academic-info-container {
        padding: $big-space;
        .academic-info-modal-details{
            background-color: $grayRecaptcha;
            border-radius: $short-space;
            padding: $small-space;

            .modal-info-box {
                margin: $medium-space;

                .information-box-section {
                    margin-bottom: $short-space;
                    color: $primaryColorGraduate;

                    .title-box-text {
                        margin: $short-space 0px;
                    }

                }

            }
        }
    }
    .visibility-curriculum-container {
        color: $primaryColor;

        @media only screen and (max-width: $breakpoint-540) {
            justify-content: center;
            padding: $small-space;
        }
    }
    .academic-info-dialog-actions {
        justify-content: flex-end;
        padding: $short-space $medium-space !important;

    @media only screen and (max-width: $breakpoint-540) {
        justify-content: center;

    }
        .next-button {
            background-color: $secondaryColor;
            font-family: $Ancizar-regular-serif;
            font-size: $font-size-h5;
            color: $white;
            margin-right: $short-space;
            padding: $short-space $small-space-secction;
        }
    }
    
}