@import '../../../index.scss';

.assign-dialog-container{
    .assign-dialog-header {
        margin: 0;
        padding-top: $small-space-secction;
    }

    .assign-dialog-content {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 8px 50px !important;
        margin-top: 0px;
        margin-bottom: $medium-space;
        .text-simple-header{
            @extend .regular-serif;
            color: $primaryColor;
            line-height: 1.5 !important;
            .text-content-header{
                @extend .bold-serif;
                color: $primaryColor;
                line-height: 1.5 !important;
                @media only screen and (max-width: $breakpoint-540) {
                    font-size: $font-size-h7;
                }
            }
        }
        .add-role-container{
            display: flex;
            justify-content: flex-end;
            margin: $small-space 0;
            .add-button{
                background-color: $primaryColorGraduate;
                color: $white;
                width: 120px;
                &:disabled {
                  background-color: $softGray !important;
                  color: $mediumGray !important;
                }
                &:hover{
                  background-color: $darkGreen;
                }
            }
        }
        .detail-container{
            justify-content: space-between !important;
            border: 1px solid #D7D7D7;
            background-color: $grayRecaptcha;
            margin: $short-space 0;
            .detail-description{
                color: $softGray;
                margin: 5px;
            }
            .del-btn{
                color: $primaryError;
                background-color: $grayRecaptcha;
                border: 1px solid $primaryError;
                margin: 5px;
            }
        }
    }

    .line{
        border-top: 2px solid $softGray;
        opacity: 1;
    }

    .assign-dialog-actions {
        padding: 14px 40px !important;
        justify-content: space-between;
        display: flex;
        flex-flow: row nowrap;
        @media only screen and (max-width: $breakpoint-540) {
            flex-flow: column nowrap;
            justify-content: center;
        }

        .back-button {
            background-color: transparent;
            border: 1px solid $primaryColorGraduate;
            color: $primaryColorGraduate;
            width: 120px;
            @media only screen and (max-width: $breakpoint-540) {
                margin-bottom: 10px;
            }
        }

        .continue-button {
            color: $white;
            width: 120px;
            @media only screen and (max-width: $breakpoint-540) {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
}