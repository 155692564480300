@import '../../../index.scss';

.select-report-container {
  margin: $medium-space 0 !important;
  border: 1px solid $primaryColor;
  border-radius: $short-space;
  .title-container {
    padding: 0 !important;
    margin: 0 !important;
    background-color: $primaryColor;
    .title {
      @extend .bold-serif;
      color: $white;
      padding: $medium-space $small-space-secction;
      @media only screen and (max-width: $breakpoint-720) {
        font-size: $font-size-h3;
      }
    }
  }
  .select-report-content{
    padding: $medium-space $small-space-secction;
    .select-report-options {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      .group-checkbox {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .label-checkbox {
          width: 250px;

          .MuiFormControlLabel-label {
            font-size: $font-size-h5;
            @extend .regular-serif;
            color: $primaryColor;
          }

          .Mui-checked {
            color: $primaryColorCompany;
          }
        }
      }
    }
  }
}