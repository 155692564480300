@import '../../../index.scss';

.sd-table__cell--header-custom {
  span {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}

.sd-body.sd-body--static {
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  padding-top: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(10 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}


.sd-element__title .sd-element__num {
  font-size: var(--sjs-font-size, 16px) !important;
  line-height: var(--sjs-font-size, 16px);
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}
