@import '../../../index.scss';

.search-box-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin: $medium-space 0 !important;
    border: 1px solid $mediumGray;
    border-radius: $short-space;
    padding: 0;
    box-shadow: 2px 2px 6px $mediumGray !important;
    padding: $big-space $small-space;

    .search-username-container{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .search-username-btn{
            background-color: $primaryColorGraduate;
            color: $white !important;
            width: 120px;
            height: 30px;
            &:disabled {
              background-color: $softGray !important;
              color: $mediumGray !important;
            }
        }
    }
}