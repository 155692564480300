@import '../../../../index.scss';

.company-info-container {
  background-color: $white;
  color: $primaryColor;

  .title-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: $medium-space 0px;

    .business-icon {
      font-size: $icon-size-small;
      margin-right: $medium-space;
    }
  }

  .summary-text {
    @extend .regular-serif;
    color: $primaryColor;
    margin-bottom: $short-space;

    .summary-span {
      font-family: $Ancizar-bold-serif !important;
    }
  }

  .offer-list-container {
    background-color: $softBlue;

    .list-container {
      background-color: $white;
      border: 1px solid $primaryColor;
      border-radius: 5px;

      .list-view {
        margin: 5px !important;
        @media only screen and (max-width: $breakpoint-540) {
          margin: 0 !important;
        }
      }
    }
  }

}
