@import '../../index.scss';

.state-dialog-container {
  .state-dialog-header {
    margin: 0;
    padding-bottom: 0;
  }

  .state-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;

    .warning-icon{
      color: $orangeDark;
      margin-right: 5px;
    }
    .warning-text{
      color: $orangeDark;
    }
    .info-text{
      color: $black;
    }

    .active-user-container{
      margin-top: 20px;
      .radio-container {
        .radio-btn {
          font-size: $font-size-h5;
          @extend .regular-serif;
          color: $primaryColor;
          margin-left: 0;
          margin-right: 60px;

          @media only screen and (max-width: $breakpoint-540) {
            margin-right: 0;
          }

          .color-btn {
            color: $primaryColorCompany;
          }
        }
      }
    }

  }

  .state-dialog-actions {
    padding: 14px 25px !important;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;

    @media only screen and (max-width: $breakpoint-540) {
      flex-flow: column nowrap;
      justify-content: center;
    }

    .cancel-button {
      background-color: $white;
      border: 1px solid $primaryColorGraduate;
      color: $primaryColorGraduate;
      width: 116px;
      @media only screen and (max-width: $breakpoint-540) {
        margin-bottom: 10px;
        font-size: $font-size-h7;
      }
    }

    .add-button {
      background-color: $primaryColorGraduate;
      color: $white;
      width: 116px;
      @media only screen and (max-width: $breakpoint-540) {
        margin-top: 10px;
        margin-left: 0;
        font-size: $font-size-h7;
      }
      &:hover {
        background-color: $primaryColorGraduateLight !important;
      }
      &:disabled {
        background-color: $softGray !important;
        color: $mediumGray !important;
      }
    }
  }
}