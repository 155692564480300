@import '../../../../index.scss';

.end-offer-container {
    background-color: $white;
    color: $primaryColor;

    .title-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: $medium-space 0px;

        .warning-icon {
            font-size: $icon-size-small;
            margin-right: $small-space;
        }
    }
    .line{
        border-bottom: 1px solid $softGray;
        margin-bottom: 20px;
    }
}