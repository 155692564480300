@import '../../../index.scss';

.action-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
    .action-container{
        margin: $medium-space auto !important;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        padding: $short-space-secction;

        .action-text-container{
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            @media only screen and (max-width: $breakpoint-960){
                margin-bottom: $medium-space;
            }
            .action-icon{
                color: $primaryColorCompany;
                font-size: 50px;
            }
            .action-description-container{
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: flex-start;
                margin-left: 10px;
                .action-state-text{
                    text-transform: uppercase;
                }
            }
        }
        .actions-buttons{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
            align-items: center;
            @media only screen and (max-width: $breakpoint-540){
                justify-content: space-around;
            }
            .cancel-action-button{
                color: $primaryColorCompany;
                background-color: $white;
                border: 2px solid $primaryColorCompany;
                margin: $short-space $medium-space;
                width: 100px;
                box-shadow: none;
                @media only screen and (max-width: $breakpoint-540){
                    margin: $short-space 0;
                }
            }
            .confirm-action-button{
                color: $white;
                background-color: $primaryColorCompany;
                margin: $short-space 0;
                width: 200px;
                @media only screen and (max-width: $breakpoint-540){
                    margin-right: $medium-space;
                }
                &:hover{
                    background-color: $primaryColor;
                    color: $white;
                }
                &:disabled{
                    background-color: $softGray !important;
                    color: $mediumGray !important;
                }
            }
        }
    }
}