@import '../../../index.scss';

.back-offers-list-section{
    background-color: $primaryColor;
}
.job-offers-detail-section{
    background-color: $white;
    .description-container {
        margin-top: $short-space-secction;
        margin-bottom: $big-space;
        padding: $small-space-secction;
        border: 2px solid $primaryColor;
        border-radius: 5px;
    }
}