@import '../../../index.scss';

.login-container{
    margin: 0;
    padding: 0;
    height: 75vh;
    @media only screen and (max-width: $breakpoint-540){
        margin-bottom: $big-space;
    }
    .login-container-img{
        background-image: url('../../../assets/images/IMG_LOGIN_EGRESADOS.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .login-container-box{
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: 40px;
        .login-header{
            .MuiGrid-item{
                flex-basis: 100% !important;
            }
            .subtitle{
                display: flex;
                flex-basis: auto;
                @extend .regular-serif;
                .subtitle-login{
                    color: $primaryColor;
                    margin-right: $short-space;
                    letter-spacing: 0px;
                    line-height: $small-space-secction; 
                }
                .subtitle-login-info{
                    letter-spacing: 0px;
                    line-height: $small-space-secction;
                    width: 80%;
                    color: $softGray;
                    padding-left: $short-space;
                }
            }
        }
        .MuiGrid-item{
            flex-basis: auto;
        }
        .login-container-form{
            display: flex;
            flex-flow: column nowrap;
            .button-login{
                color: $white;
                background-color: $secondaryColor;
                margin-top: $medium-space;
                &:disabled {
                    background-color: $softGray !important;
                    color: $mediumGray !important;
                }
            }
        }
        .button-contact{
            color: $primaryColor;
            background-color: $white;
            margin-top: $medium-space;
            &:disabled {
                background-color: $softGray !important;
                color: $mediumGray !important;
            }
        }
        
        .login-container-terms{
            padding: $short-space 0;
            margin-left: -24px;
            display: flex;
            flex-flow: row nowrap;
            .policies-icon{
                color: $primaryColor;
            }
        }
        .policies-link{
            color: $primaryColor;
            padding-top: $short-space;
            padding-left: $short-space;
            width: 100%;
            height: auto;
            margin-bottom: $small-space;
        }
        .terms-text{
            font-size: $font-size-h7;
            @extend .regular-serif;
            color: $primaryColor;
            display: contents;
        }
    }
}