@import '../../../index.scss';

.confirmed-dialog-container{
    .confirmed-dialog-content {
        padding: $short-space-secction 50px  $medium-space 50px !important;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
    .confirmed-dialog-actions {
        padding: 14px 50px !important;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        @media only screen and (max-width: $breakpoint-540) {
            flex-flow: column nowrap;
            justify-content: center;
        }
        .back-button {
            background-color: $secondaryColor;
            color: $white;
            @media only screen and (max-width: $breakpoint-540) {
                margin-top: 10px;
                margin-left: 0;
            }
            &:hover {
                background-color: $primaryColor !important;
            }
        }
    }
}