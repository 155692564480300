@import '../../../../index.scss';

.events-container {
    margin: $medium-space 0 !important;

    .line {
      border-bottom: 1px solid $softGray;
      margin-bottom: 20px;
    }

    .event-text {
        @extend .regular-serif;
        font-size: $font-size-h5;
        color: $primaryColor;
    }

    .btn-container {
        margin: 15px 0;

        .add-profile-btn {
            background-color: $primaryColorGraduate;
            color: $white;
            @extend .bold-serif;
            font-size: $font-size-h5;
            @media only screen and (max-width: 580px) {
            font-size: $font-size-h7;
            }

            &:disabled {
            background-color: $softGray !important;
            color: $mediumGray !important;
            }
        }
    }
}

.title-section-line {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;

    .group-icon {
        margin: 10px;
        color: $primaryColor;
    }

    .event-title {
        @extend .bold-serif;
        color: $primaryColor;

        @media only screen and (max-width: 580px) {
            font-size: $font-size-h3;
        }
    }
}
