@import '../../index.scss';


.title-section-line {
  display: flex;
  justify-content: flex-start !important;
  flex-flow: row nowrap;
  align-items: flex-start !important;

  .group-icon {
    margin: 10px;
    color: $primaryColor;
  }

  .message-title {
    @extend .bold-serif;
    color: $primaryColor;
    @media only screen and (max-width: 580px) {
      font-size: $font-size-h3;
    }
  }
  .message-alert {
    @extend .bold-serif;
    color: $primaryColor;
  }
  .red-title {
    @extend .bold-serif;
    color: $primaryError;
  }
  
}

.line {
  border-bottom: 1px solid $softGray;
  margin-bottom: 25px !important;
}

.mass-message-container{
  margin: $medium-space 0 !important;

  .line {
    border-bottom: 1px solid $softGray;
    margin-bottom: 20px;
  }

  .message-container {
    margin-bottom: 10px;
    border: 1px solid $primaryColor;
    border-radius: $short-space;

    .title-container-message {
      background-color: $primaryColor;

      .title {
        @extend .bold-serif;
        color: $white;
        padding: $medium-space $small-space-secction;

        @media only screen and (max-width: $breakpoint-720) {
          font-size: $font-size-h3;
        }
      }
    }

    .message-content {
      padding: $medium-space $small-space-secction;

      .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;

        .field-box-title-graduate {
          color: $primaryColorGraduate;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
        .field-box-title-company {
          color: $primaryColorCompany;
          @extend .bold-serif;
          margin-bottom: 10px;
        }
      }
      .documents-mass-message{
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
        .description-documents-text {
          font-display: auto;
          color: $black;
        }
        .list-file-element{
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          background-color: $lightGray;
          color: $mediumGray;
          padding: $small-space;
          margin: $short-space;
          .ico-delete{
            padding:0;
            height: 30px;
            margin-left: 5px;
          }
        }
        .upload-button-container {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          margin-top: $short-space;
          .upload-button {
              width: 200px;
              background-color: $secondaryColor;
              color: $white;
              padding: $small-space 0;
              @media only screen and (max-width: $breakpoint-540) {
                  width: 150px;
              }
              input {
                  display: none;
              }
              &:hover {
                  background-color: $primaryColor!important;
              }
          }

          .MuiButton-contained.Mui-disabled {
              color: rgba(0, 0, 0, 0.26) !important;
              box-shadow: none;
              background-color: rgba(0, 0, 0, 0.12) !important;
          }
        }
      }
      .add-email-button{
        width: 150px;
        background-color: $secondaryColor;
        height: 30px;
        color: $white;
        border: 1px solid $primaryColorGraduate;
        margin: $short-space;
        &:disabled {
          background-color: $softGray !important;
          color: $mediumGray !important;
        }
        &:hover {
          background-color: $primaryColor;
        }
      }
      .container-buttons-message{
        justify-content: space-between;
        @media only screen and (max-width: $breakpoint-540) {
          justify-content: space-around !important;
        }
        .cancel-button {
          width: 120px;
          background-color: $white;
          height: 30px;
          color: $primaryColorGraduate;
          border: 1px solid $primaryColorGraduate;
          margin: $short-space;
        }
        .send-button {
          width: 120px;
          background-color: $secondaryColor;
          color: $white;
          height: 30px;
          margin: $short-space;
          &:disabled {
            background-color: $softGray !important;
            color: $mediumGray !important;
          }
          &:hover {
            background-color: $primaryColor;
          }
        }
      }
    }
  }
}