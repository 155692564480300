@import '../../../index.scss';

#menu-observation{
    .MuiMenu-paper{
        width: auto;
        @media only screen and (max-width: $breakpoint-800){
            overflow-x: scroll;
            width: 500px;
        }
        @media only screen and (max-width: $breakpoint-540){
            width: 300px;
        }
        ul{
            width: fit-content !important;
        }
    }
}