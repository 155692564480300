@import '../../../index.scss';

.login-container-pre {
    margin: 0;
    padding: 0;
    min-height: 260px;
    height: 38vh;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .MuiButton-containedSizeSmall{
        padding: 6px 10px 4px!important;
    }
    #videoBgIndex {
        top: 72px;
        right: 0;
        width: 100vw;

        @media only screen and (max-width: $breakpoint-1125){
            width: auto;
        }
    }
    .text-overvideo{
        position: absolute;
        top: 72px;
        right: 0;
    }
    .filter-view{
        background-color: rgba(0, 0, 0, 0.774);

        .somos-img{
            display: block;
            margin: auto;
            margin-top: 20px;
            width: 800px;
            align-items: center;
            @media only screen and (max-width: $breakpoint-540) {
                width: 300px !important;
                margin-top: 50px !important;
            }
            @media only screen and (max-width: $breakpoint-960) {
                width: 500px;
                margin-top: 40px !important;
            }
        }

        .title-container{
            @extend .bold-serif;
            line-height: $small-space-secction;
            color: $white;
            letter-spacing: 0px;
            text-align: center;
            padding: 20px 0px;
            @media only screen and (max-width: $breakpoint-960) {
                font-size: $font-size-h2 !important;
            }
        }
        .btn-container{
               .button-login{
                z-index: 2;
                width: fit-content;
                margin: auto;
                color: $primaryColor;
                background-color: $white;
                margin-top: $medium-space;
                margin-bottom: $medium-space;
                &:hover {
                    background-color: $primaryColorGraduateLight;
                    color: $primaryColor;
                }
            }
        }
    }



}