@import '../../../index.scss';

.detail-new-container-view {
  background-color: $white;

  .img-container-view {
    .img-view {
      width: 100%;
      height: 342px;
      object-fit: cover;
      @media only screen and (max-width: 420px) {
        height: 200px !important;
      }

      @media only screen and (max-width: 840px) {
        height: 250px;
      }
    }
  }

  .summary-container-view {
    align-items: flex-start;

    @media only screen and (max-width: 959px) {
      justify-content: center !important;
    }

    .category-container-text {
      @extend .regular-serif;
      color: $primaryColor;
      background-color: $warningColor;
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: $short-space;
      .category-icon{
        font-size: $icon-size-small;
        margin: 0 $short-space;
      }
    }

    .summary-title-view {
      width: 100%;
      @extend .bold-serif;
      color: $primaryColor;
      margin-right: 50px;
      margin-bottom: 10px;

      @media only screen and (max-width: 840px) {
        font-size: 28px;
      }
    }

    .summary-description-view {
      width: 100%;
      @extend .light-serif;
      color: $primaryColor;
      margin-right: 50px;

      @media only screen and (max-width: 840px) {
        font-size: $font-size-h4;
      }
    }

    .summary-content-view {
      width: 100%;
      @extend .light-serif;
      color: $primaryColor;
      margin-right: 50px;

      @media only screen and (max-width: 840px) {
        font-size: $font-size-h6;
      }
    }

    .info-event-container-view {
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #D7D7D7;
      border-radius: 5px;
      opacity: 1;
      margin-top: 20px;
      z-index:1 !important;

      @media only screen and (max-width: 959px) {
        max-width: 350px;
        justify-content: center !important;
      }

      .event-summary-content-view {
        margin-top: 20px;
        margin-left: 10px;

        .event-title {
          @extend .bold-serif;
          color: $primaryColor;
          margin-bottom: 5px;
          margin-top: 5px;

          @media only screen and (max-width: 420px) {
            font-size: $font-size-h6;
          }
        }

        .date-section {
          background-color: $white;
          color: $secondaryColor;
          @extend .regular-serif;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: $short-space;

          .icon-class {
            font-size: $icon-size-small;
            margin: 0 $short-space;
          }
          @media only screen and (max-width: 420px) {
            font-size: $font-size-h6;
          }
        }
      }

      .enroll-container {
        justify-content: center;

        .enroll-btn {
          height: 40px;
          width: 200px;
          margin-top: 10px;
          margin-bottom: 20px;
          color: $white;
          background-color: $secondaryColor;
          &:hover {
            background-color: $primaryColor !important;
          }
          &:disabled {
            background-color: $softGray !important;
            color: $mediumGray !important;
          }
        }
        .enroll-text-container{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 200px;
          margin-top: 10px;
          margin-bottom: 20px;
          color: $primaryColor;
        }
      }
    }

    .cards-related-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-evenly !important;
      @media only screen and (max-width: 824px) {
        justify-content: center !important;
      }
      .cards-related-container-title {
        margin-top: $medium-space;
        @extend .bold-serif;
        color: $primaryColor;

        @media only screen and (max-width: 824px) {
          font-size: 28px;
        }
      }

      .cards-related-grid {
        display: flex;
        flex-flow: row nowrap;
        @media only screen and (max-width: 824px) {
          flex-flow: column nowrap;
        }
      }
    }
  }
}
