@import '../../../index.scss';

.register-dialog-container{
  .register-dialog-header {
    margin: 0;
    padding-bottom: 0;
   }
  .register-dialog-content {
    @extend .regular-serif;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 50px !important;

    .register-dialog-desc{
      color: $black;
      @media only screen and (max-width: $breakpoint-540) {
        font-size: $font-size-h7;
      }
    }

    .text-content-dialog {
      color: $black;
      line-height: 1.5 !important;
      @media only screen and (max-width: $breakpoint-540) {
        font-size: $font-size-h7;
      }
    }
  }
  .register-dialog-actions {
    padding: 14px 50px !important;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: $breakpoint-540) {
      flex-flow: column nowrap;
      justify-content: center;
    }
    .back-button{
      background-color: $white;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      @media only screen and (max-width: $breakpoint-540) {
        margin-bottom: 10px;
      }
    }
    .continue-button {
      background-color: $secondaryColor;
      color: $white;
      @media only screen and (max-width: $breakpoint-540) {
        margin-top: 10px;
        margin-left: 0;
      }
      &:hover {
        background-color: $primaryColor !important;
      }
    }
  }
}