@import '../../../index.scss';

.graduate-change-action-container, .offer-edit-title-container{
    width: 100%;
    .change-action-main-title{
        @extend .bold-serif;
        color: $primaryColor;
        margin: $medium-space 0;
    }
    .table-results{
        @extend .bold-serif;
        color: $mediumGray;
        margin: $short-space 0;
    }
}