@import '../../index.scss';

.users-company-form{

    .update-container{
      margin-top: $short-space-secction !important;
      margin-bottom: $short-space-secction !important;
      text-align: end;
      @media only screen and (max-width: $breakpoint-540) {
        text-align: center;
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
      }
      .update-btn {
        background-color: $secondaryColor;
        color: $white;
        &:disabled {
          background-color: $softGray !important;
          color: $mediumGray !important;
        }
      }
    }

    .disable-container {
        margin-top: $short-space-secction !important;
        margin-bottom: $short-space-secction !important;
        @media only screen and (max-width: $breakpoint-540) {
          text-align: center;
          margin-top: $medium-space !important;
          margin-bottom: $medium-space !important;
        }
        .disable-btn {
            background-color: $white;
            color: $primaryColorGraduate;
            border: 1px solid $primaryColorGraduate;
        }
    }

    .field-box-margin {
        margin-top: $medium-space !important;
        margin-bottom: $medium-space !important;
        .field-box-title {
          color: $primaryColorCompany;
        }
        .checkbox {
          margin: 0 !important;
          .MuiFormControlLabel-label {
            font-size: $font-size-h5;
            @extend .regular-serif;
            color: $primaryColor;
          }
          .Mui-checked {
            color: $primaryColorCompany;
          }
        }
    }
}